import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";

const Materials = ({ trainingComponent }) => (
  <Markdown>{trainingComponent.data.text}</Markdown>
);

Materials.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Materials;
