import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";

@observer
class Thumbnail extends Component {
  render() {
    return (
      <div style={{ margin: "5px 0" }}>
        <img src={this.props.src} className="img-thumbnail" />
      </div>
    );
  }
}

Thumbnail.propTypes = {
  src: PropTypes.string.isRequired,
};

export default Thumbnail;
