import React, { useEffect, useState } from "react";
import * as JsRoutes from "../../../util/routes.js";

const ContactLists = ({ user, onChange }) => {
  const [contactLists, setContactLists] = useState([]);
  const [currentList, setCurrentList] = useState(null);

  useEffect(() => {
    if (!user) {
      return;
    }

    fetch(JsRoutes.contact_lists_path())
      .then((r) => r.json())
      .then(setContactLists);
  }, [user]);

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    if (value) {
      const selected = contactLists.find((c) => c.id.toString() === value);
      setCurrentList(selected);
      onChange(selected);
    } else {
      setCurrentList(null);
      onChange(null);
    }
  };

  return (
    <select
      onChange={handleChange}
      value={currentList ? currentList.id : ""}
      className="ml-2 mr-2 input-raw"
    >
      <option value="">Create a new list</option>
      {contactLists.map((contactList) => (
        <option key={`contact-list-${contactList.id}`} value={contactList.id}>
          {contactList.name}
        </option>
      ))}
    </select>
  );
};

export default ContactLists;
