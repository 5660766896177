import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { action } from "mobx";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";

@observer
class PWREPrompt extends Component {
  @action.bound
  handleTextChange(event) {
    this.props.prompt.text = event.target.value;
  }

  render() {
    return (
      <FormGroup name="prompt" label="Prompt">
        {(formControl) => (
          <textarea
            {...formControl}
            value={this.props.prompt.text}
            placeholder="Prompt"
            onChange={this.handleTextChange}
          />
        )}
      </FormGroup>
    );
  }
}

PWREPrompt.propTypes = {
  prompt: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
};

@observer
class PracticeTchart extends TrainingComponentEditor {
  initializeData() {
    return {
      prompts: [{ text: "" }, { text: "" }, { text: "" }, { text: "" }],
    };
  }

  @action.bound
  handleTextChange(event) {
    this.data.text = event.target.value;
  }

  render() {
    return (
      <Fragment>
        {this.data.prompts.map((prompt, idx) => (
          <PWREPrompt
            key={`${this.trainingComponent.id}-prompt-${idx}`}
            prompt={prompt}
          />
        ))}
      </Fragment>
    );
  }
}

export default PracticeTchart;
