import React from "react";
import Modal from "../../Modal";
import TrainingCompleteOptions from "./TrainingCompleteOptions";

const TrainingCompleteSuccessMessage = ({ isOpen, onRequestClose }) => {
  return (
    <Modal isOpen={isOpen} onRequestClose={onRequestClose}>
      <div className="pb-8 px-12 text-primary-grey-blue">
        <h1>Mark Complete</h1>
        <div className="mt-8">
          <TrainingCompleteOptions
            title={
              <React.Fragment>
                <p className="font-bold">
                  Congrats on learning and practicing!
                </p>
                <div className="bg-light-grey-03 text-sm font-bold px-8 py-2">
                  You can still share your practice later from your My Progress
                  page.
                </div>
              </React.Fragment>
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default TrainingCompleteSuccessMessage;
