import React, { useState } from "react";
import Login from "./Login";
import SignUpSubscription from "./signup/tlaconline/SignUpSubscription";
import TexasSignUp from "./signup/texas/TexasSignUp";

const AuthComponent =
  window.tlac_instance == "texas" ? TexasSignUp : SignUpSubscription;

const Authentication = (props) => {
  const [authType, setAuthType] = useState(props.authType || "login");

  return authType === "login" ? (
    <Login onChangeAuthType={setAuthType} />
  ) : (
    <AuthComponent
      subscriptionPlans={props.subscriptionPlans}
      edPrepPrograms={props.edPrepPrograms}
      onChangeAuthType={setAuthType}
    />
  );
};

export default Authentication;
