import React from "react";

const FieldError = ({ children }) => {
  if (children) {
    return <div className="field-error">{children}</div>;
  }
  return null;
};

export default FieldError;
