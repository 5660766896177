import React, { useEffect, useState } from "react";
import gaTrackEvent from "../../../gaTrackEvent";
import parseServerErrors from "../../../util/parseServerErrors";
import * as JsRoutes from "../../../util/routes";
import xhrPost from "../../../util/xhrPost";
import FieldError from "../../FieldError";
import AccountForm from "./AccountForm";
import PaymentForm from "./PaymentForm";

export const PlansList = (props) => {
  const { subscriptionPlans, step } = props;
  const formatPrice = (x) => parseFloat(x).toFixed(2).replace(/\.0+$/, "");

  return (
    <div
      className={`${
        step === 1 ? "bg-light-grey-02" : "bg-white"
      } w-full p-4 text-vs`}
    >
      <p className="mb-2">
        <strong>Pricing</strong>
      </p>
      {subscriptionPlans
        .filter((s) => s.group)
        .map((s) => (
          <div className="flex" key={`plan-desc-${s.id}`}>
            <div className="w-3/5">
              {s.min}-{s.max} accounts
            </div>
            <div className="w-2/5">${formatPrice(s.price)}/user</div>
          </div>
        ))}
    </div>
  );
};

const stepClass = (isActive) =>
  isActive ? "progress-item-active-50" : "progress-item-50";

const SignUpSubscription = ({ subscriptionPlans, signedIn = false }) => {
  const [step, setStep] = useState(1);
  const [account, setAccount] = useState(null);
  const [payment, setPayment] = useState(null);
  const [working, setWorking] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    gaTrackEvent("sign-up", "view-0", "primary");
  }, []);

  const handleChangeAccount = (newAccount) => {
    gaTrackEvent("sign-up", "account-1", "primary");
    setAccount(newAccount);
    setStep(2);
  };

  const handlePayment = (formResult) => {
    const newPayment = formResult.payment;
    const subscriptionPlan = formResult.subscriptionPlan;
    gaTrackEvent("sign-up", "subscribe-3", "primary");
    setPayment(newPayment);
    setWorking(true);

    const path = signedIn
      ? JsRoutes.subscriptions_path()
      : JsRoutes.user_registration_path();

    xhrPost(path, {
      data: {
        user: {
          email: account.email,
          password: account.password,
          password_confirmation: account.password_confirmation,
          first_name: account.first_name,
          last_name: account.last_name,
          email_consent: newPayment.email_consent,
        },
        subscription_plan: {
          id: subscriptionPlan.plan.id,
          group_name: subscriptionPlan.groupName,
          accounts: subscriptionPlan.accounts,
          start_at: subscriptionPlan.startAt,
        },
        transaction: {
          pay_by: newPayment.pay_by,
          coupon: newPayment.coupon,
          nonce: newPayment.nonce,
          invoice_address: newPayment.invoice_address,
        },
      },
      success: (_data, response) => {
        const newLocation =
          response.headers.get("Location") || JsRoutes.root_path();
        window.location.href = newLocation;
      },
      error: (e) => {
        setWorking(false);
        const errorMessage =
          "Something went wrong when creating your account. Please try again later, or contact us for help.";
        e.response
          .json()
          .then((data) => {
            setErrors(parseServerErrors(data, errorMessage));
          })
          .catch((e) => {
            setErrors([errorMessage]);
          });
      },
    });
  };

  const stepBack = () => setStep(step - 1);

  const stepClasses = [stepClass(step == 1), stepClass(step == 2)];

  return (
    <div className="px-3-5rem pb-12 text-primary-grey-blue md:w-56rem text-sm">
      <h1 className="leading-none text-primary-grey-blue-02 mb-12">
        {signedIn ? "Renew Subscription" : "Sign Up"}
      </h1>
      <div className="py-6 px-8 bg-white">
        <ul id="progressbar" className="flex font-bold">
          <li className={stepClasses[0]}>1. Account</li>
          <li className={stepClasses[1]}>2. Payment</li>
        </ul>
        {step === 1 ? (
          <AccountForm
            signedIn={signedIn}
            subscriptionPlans={subscriptionPlans}
            onSubmit={handleChangeAccount}
            value={account}
          />
        ) : null}
        {step === 2 ? (
          <PaymentForm
            signedIn={signedIn}
            onCancel={stepBack}
            onSubmit={handlePayment}
            disabled={working}
            account={account}
            subscriptionPlans={subscriptionPlans}
          />
        ) : null}
        {errors.map((error, idx) => (
          <FieldError key={`error-${idx}`}>{error}</FieldError>
        ))}
      </div>
    </div>
  );
};

export default SignUpSubscription;
