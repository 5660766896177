import React, { useRef, useState } from "react";

const BulkUploadInput = ({ name, onChange, onSubmit }) => {
  const inputRef = useRef();

  const [disabled, setDisabled] = useState(true);

  const handleChange = () => {
    setDisabled(!inputRef.current.files.length);
    onChange && onChange();
  };

  const handleClick = (e) => {
    e.preventDefault();
    inputRef.current.click();
  };

  return (
    <>
      <p className="mb-4">
        Upload a CSV file with the users to be imported with{" "}
        <a className="link" href="/setup_users_sample.csv">
          this header and structure
        </a>
        .
      </p>
      <div className="flex input">
        <button className="button-form mr-4" onClick={handleClick}>
          Choose a File
        </button>
        <input
          accept=".csv"
          className="flex-grow mr-4"
          name={name}
          onChange={handleChange}
          ref={inputRef}
          type="file"
        />
        <button
          className="button-form"
          disabled={disabled}
          onClick={onSubmit}
          type="submit"
        >
          Import
        </button>
      </div>
    </>
  );
};

export default BulkUploadInput;
