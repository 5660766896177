import React, { Component } from "react";
import PropTypes from "prop-types";

class Image extends Component {
  constructor(props) {
    super(props);
    const atts = this.props.trainingComponent.attachments;
    this.attachments = atts && atts.length > 0 ? atts : [];
  }

  get data() {
    return this.props.trainingComponent.data;
  }

  singleImage(proportion) {
    const img = this.attachments[0];
    const alt = this.data.altTexts ? this.data.altTexts[0] : "";

    if (proportion === 100) {
      return <img src={img} alt={alt} />;
    } else {
      return (
        <div className="text-center">
          <img src={img} alt={alt} style={{ maxWidth: "65%" }} />
        </div>
      );
    }
  }

  multipleImages() {
    // set of 3 images
    const images = this.attachments.map((a, i) => {
      if (i >= 3) return null;

      const alt = this.data.altTexts ? this.data.altTexts[i] : "";
      const style = {
        maxWidth: "32%",
        display: "inline-block",
        paddingRight: "2%",
      };
      return <img key={`img-m-${i}`} src={a} alt={alt} style={style} />;
    });

    return (
      <div className="text-center" style={{ marginRight: "-2%" }}>
        {images}
      </div>
    );
  }

  render() {
    if (this.data.numImages === 1) {
      return this.singleImage(this.data.proportion);
    } else {
      return this.multipleImages();
    }
  }
}

Image.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Image;
