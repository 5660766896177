import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import { action } from "mobx";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";

@observer
class TchartLine extends Component {
  @action.bound
  handleStudentChange(event) {
    this.props.line.student = event.target.value;
  }

  @action.bound
  handleTeacherChange(event) {
    this.props.line.teacher = event.target.value;
  }

  render() {
    return (
      <div className="form-row">
        <FormGroup name="teacher" label="Teacher" className="col-md-6">
          {(formControl) => (
            <textarea
              {...formControl}
              placeholder="Teacher will..."
              value={this.props.line.teacher}
              onChange={this.handleTeacherChange}
            />
          )}
        </FormGroup>
        <FormGroup name="student" label="Student" className="col-md-6 ">
          {(formControl) => (
            <textarea
              {...formControl}
              placeholder="Student will..."
              value={this.props.line.student}
              onChange={this.handleStudentChange}
            />
          )}
        </FormGroup>
      </div>
    );
  }
}

TchartLine.propTypes = {
  line: PropTypes.shape({
    student: PropTypes.string,
    teacher: PropTypes.string,
  }).isRequired,
};

@observer
class PracticePrompt extends Component {
  @action.bound
  handleTextChange(event) {
    this.props.prompt.text = event.target.value;
  }

  render() {
    return (
      <FormGroup name="prompt" label="Prompt">
        {(formControl) => (
          <textarea
            {...formControl}
            value={this.props.prompt.text}
            placeholder="Prompt"
            onChange={this.handleTextChange}
          />
        )}
      </FormGroup>
    );
  }
}

PracticePrompt.propTypes = {
  prompt: PropTypes.shape({
    text: PropTypes.string,
  }).isRequired,
};

@observer
class PracticeTchart extends TrainingComponentEditor {
  initializeData() {
    return {
      prompts: [{ text: "" }, { text: "" }],
      lines: [
        { student: "", teacher: "" },
        { student: "", teacher: "" },
        { student: "", teacher: "" },
        { student: "", teacher: "" },
      ],
    };
  }

  @action.bound
  handleTextChange(event) {
    this.data.text = event.target.value;
  }

  render() {
    return (
      <div>
        {this.data.prompts.map((prompt, idx) => (
          <PracticePrompt
            key={`${this.props.trainingComponent.id}-prompt-${idx}`}
            prompt={prompt}
          />
        ))}
        <div className="form-row">
          <div className="form-group col-md-6">
            <span className="rl-component-tchart-heading">Teacher will...</span>
          </div>
          <div className="form-group col-md-6">
            <span className="rl-component-tchart-heading">Student will...</span>
          </div>
        </div>
        {this.data.lines.map((line, idx) => (
          <TchartLine
            key={`${this.props.trainingComponent.id}-line-${idx}`}
            line={line}
          />
        ))}
      </div>
    );
  }
}

export default PracticeTchart;
