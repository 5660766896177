import React, { Fragment } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import Thumbnail from "../Thumbnail";

@observer
class Image extends TrainingComponentEditor {
  constructor(props) {
    super(props);
    if (!this.data.altTexts) {
      this.props.trainingComponent.data.altTexts = {};
    }
  }

  initializeData() {
    return {
      numImages: 1,
      proportion: 100,
      altTexts: {},
    };
  }

  @action.bound
  addFileInput(attachmentIndex) {
    const ref = React.createRef();
    this.trainingComponent.addFile(attachmentIndex, ref);

    let thumbnail = null;
    if (this.trainingComponent.attachments.length > attachmentIndex) {
      const url = this.trainingComponent.attachments[attachmentIndex]
        .thumbnail_url;
      thumbnail = <Thumbnail src={url} />;
    }

    return (
      <FormGroup
        name="file"
        label="Image File"
        key={`${this.props.trainingComponent.id}-attachment-${attachmentIndex}`}
      >
        {({ name, id }) => (
          <Fragment>
            {thumbnail}
            <input
              name={name}
              id={id}
              type="file"
              ref={ref}
              className="form-control-file"
            />
            <input
              name="alt_text"
              type="text"
              className="form-control u-margin-top"
              placeholder="Alt text"
              value={this.data.altTexts[attachmentIndex]}
              data-attachment-index={attachmentIndex}
              onChange={this.handleAltTextChange}
            />
          </Fragment>
        )}
      </FormGroup>
    );
  }

  @action.bound
  handleNumImageChange(evt) {
    const numImages = parseInt(evt.target.value);

    this.trainingComponent.clearFiles();
    this.data.numImages = numImages;

    if (numImages == 3) {
      this.data.proportion = 33;
    } else {
      this.data.proportion = 100;
    }
  }

  @action.bound
  handleProportionChange(evt) {
    this.data.proportion = parseInt(evt.target.value);
  }

  @action.bound
  handleAltTextChange(evt) {
    const index = evt.target.dataset.attachmentIndex;
    this.data.altTexts[index] = evt.target.value;
  }

  render() {
    const inputs = [];
    for (let i = 0; i < this.data.numImages; i++) {
      inputs.push(this.addFileInput(i));
    }

    return (
      <Fragment>
        <FormGroup name="num-images" label="Number of images">
          {(formControl) => (
            <select
              {...formControl}
              value={this.data.numImages}
              onChange={this.handleNumImageChange}
            >
              <option value="1">1</option>
              <option value="3">3</option>
            </select>
          )}
        </FormGroup>
        <FormGroup name="proportion" label="Proportion">
          {(formControl) =>
            this.data.numImages == 3 ? (
              <select {...formControl} value={this.data.proportion} disabled>
                <option value="33">Three at 33%</option>
              </select>
            ) : (
              <select
                {...formControl}
                value={this.data.proportion}
                onChange={this.handleProportionChange}
              >
                <option value="100">One at 100%</option>
                <option value="65">One at 65%</option>
              </select>
            )
          }
        </FormGroup>
        {inputs}
      </Fragment>
    );
  }
}

export default Image;
