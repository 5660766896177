import React from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";

import TrainingComponent from "./TrainingComponent";

const TCItem = SortableElement(
  observer(({ training, trainingComponent, store }) => {
    const handleDelete = () => {
      store.removeComponent(trainingComponent);
    };

    return (
      <li key={trainingComponent.id} className="mb-6">
        <TrainingComponent
          training={training}
          trainingComponent={trainingComponent}
          store={store}
          onDelete={handleDelete}
        />
      </li>
    );
  })
);

const TCList = SortableContainer(
  observer(({ training, trainingComponents, store }) => (
    <ul className="mt-2">
      {trainingComponents.map((tc, idx) => (
        <TCItem
          key={tc.id}
          store={store}
          training={training}
          trainingComponent={tc}
          index={idx}
        />
      ))}
    </ul>
  ))
);

const TrainingComponents = observer(
  ({ training, trainingComponents, store }) => {
    const onSortEnd = ({ oldIndex, newIndex }) => {
      trainingComponents.replace(
        arrayMove(trainingComponents.slice(), oldIndex, newIndex)
      );
    };

    const handleAddComponent = (event) => {
      event.preventDefault();
      store.addBlankComponent();
    };

    return (
      <div>
        <hr className="my-4" />
        <label className="text-sm">Training Components</label>

        <TCList
          training={training}
          trainingComponents={trainingComponents}
          store={store}
          onSortEnd={onSortEnd}
          useDragHandle={true}
          helperClass="dragging"
        />

        <button
          className="button-form add-component-btn"
          onClick={handleAddComponent}
        >
          Add Component
        </button>
      </div>
    );
  }
);

TrainingComponents.propTypes = {
  store: PropTypes.object.isRequired,
};

export default TrainingComponents;
