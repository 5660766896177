import React, { useState } from "react";
import PaymentForm from "./signup/tlaconline/PaymentForm";
import xhrPost from "../util/xhrPost";
import * as JsRoutes from "../util/routes";
import FieldError from "./FieldError";
import parseServerErrors from "../util/parseServerErrors";

const RenewSubscription = ({
  account,
  onCancel,
  subscriptionPlans,
  renewal,
}) => {
  const [working, setWorking] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleChangePayment = ({ payment, subscriptionPlan }) => {
    setWorking(true);

    xhrPost(JsRoutes.renewal_subscriptions_path(), {
      data: {
        subscription_plan: {
          id: subscriptionPlan.plan.id,
          accounts: subscriptionPlan.accounts,
          start_at: subscriptionPlan.startAt,
        },
        transaction: {
          pay_by: payment.pay_by,
          coupon: payment.coupon,
          nonce: payment.nonce,
          invoice_address: payment.invoice_address,
        },
      },
      success: (_data, response) => {
        const newLocation =
          response.headers.get("Location") || JsRoutes.root_path();
        window.location.href = newLocation;
      },
      error: (e) => {
        setWorking(false);
        const errorMessage =
          "Something went wrong when renewing the account. Please try again later, or contact us for help.";
        e.response
          .json()
          .then((data) => {
            setErrors(parseServerErrors(data, errorMessage));
          })
          .catch((e) => {
            setErrors([errorMessage]);
          });
      },
    });
  };

  return (
    <div className="px-3-5rem pb-12 text-primary-grey-blue md:w-56rem text-sm">
      <h1 className="leading-none text-primary-grey-blue-02 mb-12">
        Account Renewal
      </h1>
      <div className="py-6 px-8 bg-white">
        <PaymentForm
          account={account}
          onCancel={onCancel}
          onSubmit={handleChangePayment}
          disabled={working}
          subscriptionPlans={subscriptionPlans}
          renewal={renewal}
        />
        {errors.map((error, idx) => (
          <FieldError key={`error-${idx}`}>{error}</FieldError>
        ))}
      </div>
    </div>
  );
};

export default RenewSubscription;
