import React from "react";
import gaTrackEvent from "../../gaTrackEvent";
import * as JsRoutes from "../../util/routes";

const BulkUploadTeaser = ({ gaEventLabel }) => (
  <div className="bg-light-grey-02 p-4 rounded-md">
    You can also{" "}
    <a
      className="link"
      href={JsRoutes.organization_setup_step_5_path()}
      onClick={() => gaTrackEvent("manager", "bulkupload", gaEventLabel)}
    >
      use the Bulk Upload method
    </a>
    {" "}to add all of your team members by uploading a single spreadsheet for
    all roles and teams.
  </div>
);

export default BulkUploadTeaser;
