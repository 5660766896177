import { action, observable } from "mobx";
import { observer } from "mobx-react";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import gaTrackEvent from "../../../gaTrackEvent";
import guid from "../../../util/guid";

const MOBILE_BROWSERS_RE = /Android|webOS|iPhone|iPad|iPod|BB10|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i;

@observer
class Camera extends Component {
  @observable status = null;
  @observable showAccept = true;
  @observable showActions = true;
  @observable initialized = false;

  id = guid();

  cameratagSetupInterval = null;

  componentDidMount() {
    this.cameratagSetupInterval = window.setInterval(this.setupCameratag, 500);
  }

  componentWillUnmount() {
    if (window.CameraTag) {
      try {
        this.cameraTag.destroy();
      } catch (err) {
        console.log(
          `Something went wrong when cleaning up Cameratag: ${err.message}`
        );
      }
    }
  }

  @action.bound
  setupCameratag() {
    if (!window.CameraTag) {
      return;
    }

    CameraTag.setup();
    CameraTag.observe(this.id, "initialized", this.onInitialize);
  }

  get cameraTag() {
    return CameraTag.cameras[this.id];
  }

  get stack() {
    return !!MOBILE_BROWSERS_RE.test(navigator.userAgent || "")
      ? "mobile"
      : "webrtc";
  }

  get video() {
    return this.cameraTag.getVideo();
  }

  get src() {
    const mediaSrc = Object.values(this.video.medias).find(
      (v) => v && v.match(/\.(mp4|webm)$/)
    );

    if (mediaSrc) {
      return mediaSrc;
    }

    return (
      this.hostedCameraElement.querySelector('video[src^="http"]') ||
      this.hostedCameraElement.querySelector("video")
    ).src;
  }

  get cameraResult() {
    return {
      id: this.id,
      video: this.video,
      src: this.src,
      metadata: this.props.metadata,
    };
  }

  @action.bound
  onInitialize() {
    if (this.initialized) {
      return;
    }

    this.initialized = true;
    window.clearInterval(this.cameratagSetupInterval);
    this.cameratagSetupInterval = null;

    CameraTag.observe(this.id, "readyToRecord", this.onReadyToRecord);
    CameraTag.observe(this.id, "recordingStarted", this.onRecordingStarted);
    CameraTag.observe(this.id, "recordingStopped", this.onRecordingStopped);
    CameraTag.observe(this.id, "published", this.onPublished);
    CameraTag.observe(this.id, "processed", this.onProcessed);

    // the stop_playback div is dinamically inserted by cameratag after initialize
    this.stopPlayback = this.hostedCameraElement.querySelector(
      ".cameratag_stop_playback"
    );
    this.stopPlayback.addEventListener("click", this.onStopPlayback);
  }

  @action.bound
  onRecordingStarted() {
    gaTrackEvent(
      "Module",
      "record",
      `${this.props.metadata.training_id} - ${this.props.metadata.title}`
    );
  }

  @action.bound
  onRecordingStopped() {
    this.showAccept = true;
  }

  @action.bound
  onReadyToRecord() {
    // prevent more than one camera to be active at a time
    const cameras = CameraTag.cameras;
    for (let key in cameras) {
      const cam = cameras[key];
      if (key != this.id && cam.getState() == "recording") {
        cam.stopRecording();
      }
    }
  }

  @action.bound
  onAccept(e) {
    e.preventDefault();
    this.showAccept = false;
    this.cameraTag.publish();
  }

  @action.bound
  onReview(e) {
    e.preventDefault();

    // hide actions & show stopPlayback
    this.showActions = false;
    this.stopPlayback.style.display = "block";

    // when video ends show actions
    this.cameraTag.play();
  }

  @action.bound
  onStopPlayback(e) {
    this.cameraTag.stopPlayback();
    this.showActions = true;
  }

  @action.bound
  onPublished() {
    // This method is being executed by Cameratag, which swallows errors.
    // Use setTimeout to break free.
    window.setTimeout(() => {
      this.props.onAccept({
        ...this.cameraResult,
      });
    }, 0);
  }

  @action.bound
  onProcessed() {
    // This method is being executed by Cameratag, which swallows errors.
    // Use setTimeout to break free.
    window.setTimeout(() => {
      this.props.onProcessed();
    }, 0);
  }

  render() {
    const cameraTagHTML = {
      __html: `
        <camera
          data-signature='${this.props.signature}'
          data-signature-expiration='${this.props.signatureExpiration}'
          id='${this.id}'
          data-app-id='${this.props.appId}'
          data-metadata='${JSON.stringify(this.props.metadata)}'
          data-upload-on-select='false'
          data-sources='record'
          data-pre-roll-length='3'
          data-minlength='0'
          data-maxlength='60'
          data-poll-for-processed='true'
          data-webrtc='true'
          data-width="272"
          data-height="204"
          data-stack='${this.stack}'
        />
      `,
    };

    return (
      <Fragment>
        <div
          className={`rl-camera ${this.initialized ? "initialized" : ""}`}
          ref={(e) => (this.hostedCameraElement = e)}
          data-id={this.id}
          dangerouslySetInnerHTML={cameraTagHTML}
        />
        <div className="rl-save-results" />
        <div id={`${this.id}-mobile-start-screen`} className="cameratag_start">
          <div className="tlac-cameratag-actions flex items-center justify-center">
            <div>
              <a className="cameratag_record gtm-video-record button-camera">
                Record
              </a>
            </div>
          </div>
        </div>
        <div id={`${this.id}-start-screen`} className="cameratag_start">
          <div className="tlac-cameratag-actions flex items-center justify-center">
            <div>
              <a className="cameratag_record gtm-video-record button-camera">
                Record
              </a>
            </div>
          </div>
        </div>
        <div
          id={`${this.id}-accept-screen`}
          className="cameratag_accept"
          style={{ display: this.showActions ? "block" : "none" }}
        >
          <div className="tlac-cameratag-actions flex flex-wrap items-center justify-center">
            <div>
              <a
                className="cameratag_play gtm-video-review button-secondary"
                onClick={this.onReview}
              >
                <i className="fa fa-check mr-2" />
                Review
              </a>
            </div>
            <div>
              <a className="cameratag_record gtm-video-re-record button-camera">
                <i className="fa fa-video-camera mr-2"></i>
                Re-record
              </a>
            </div>
            <div className="rl-accept">
              <a
                className="gtm-video-accept button-primary"
                style={{ display: this.showAccept ? "inline-block" : "none" }}
                onClick={this.onAccept}
              >
                <i className="fa fa-play mr-2" />
                Accept
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

Camera.propTypes = {
  appId: PropTypes.string.isRequired,
  metadata: PropTypes.object,
  signature: PropTypes.string.isRequired,
  signatureExpiration: PropTypes.number.isRequired,
  onAccept: PropTypes.func,
};

export default Camera;
