import React, { Fragment } from "react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import { observer } from "mobx-react";
import { action } from "mobx";

@observer
class Closing extends TrainingComponentEditor {
  initializeData() {
    return {
      closingType: "planning",
      buttonLabel: "",
    };
  }

  @action.bound
  changeClosingType(evt) {
    this.data.closingType = evt.target.value;
  }

  @action.bound
  changeButtonLabel(evt) {
    this.data.buttonLabel = evt.target.value;
  }

  render() {
    return (
      <Fragment>
        <FormGroup name="closing-type" label="Closing Type">
          {(formControl) => (
            <select
              {...formControl}
              value={this.data.closingType}
              onChange={this.changeClosingType}
            >
              <option value="planning">Planning</option>
              <option value="video">Video</option>
            </select>
          )}
        </FormGroup>
        <FormGroup name="button-label" label="Button Label">
          {(formControl) => (
            <input
              {...formControl}
              type="text"
              value={this.data.buttonLabel}
              onChange={this.changeButtonLabel}
            />
          )}
        </FormGroup>
      </Fragment>
    );
  }
}

export default Closing;
