import React, { Fragment } from "react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import { action } from "mobx";
import { observer } from "mobx-react";

@observer
class PracticeWriteExemplar extends TrainingComponentEditor {
  initializeData() {
    return {
      prompt: "",
      exemplar: "",
    };
  }

  @action.bound
  handlePromptChange(evt) {
    this.data.prompt = evt.target.value;
  }

  @action.bound
  handleExemplarChange(evt) {
    this.data.exemplar = evt.target.value;
  }

  render() {
    return (
      <Fragment>
        <FormGroup name="prompt" label="Prompt">
          {(formControl) => (
            <textarea
              {...formControl}
              value={this.data.prompt}
              onChange={this.handlePromptChange}
              placeholder="Prompt"
            />
          )}
        </FormGroup>
        <FormGroup name="exemplar" label="Exemplar">
          {(formControl) => (
            <textarea
              {...formControl}
              value={this.data.exemplar}
              onChange={this.handleExemplarChange}
              placeholder="Exemplar"
            />
          )}
        </FormGroup>
      </Fragment>
    );
  }
}

export default PracticeWriteExemplar;
