import sortBy from "lodash/sortBy";
import uniqBy from "lodash/uniqBy";
import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import replaceArrayItem from "../util/replaceArrayItem";
import Modal from "./Modal";
import PurchaseAccounts from "./PurchaseAccounts";
import xhrPost from "../util/xhrPost";
import * as JsRoutes from "../util/routes";
import FieldError from "./FieldError";
import parseServerErrors from "../util/parseServerErrors";

const ROLES = {
  teacher: "Teacher",
  coach: "Coach",
  manager: "Manager",
};

const PurchaseAccountsModal = ({
  isOpen,
  onClose,
  numAccounts,
  startDate,
  endDate,
  groupName,
  subscriptionPlans,
  onSuccess,
}) => (
  <Modal isOpen={isOpen} onRequestClose={onClose}>
    <PurchaseAccounts
      numAccounts={numAccounts}
      startDate={startDate}
      endDate={endDate}
      groupName={groupName}
      onCancel={onClose}
      subscriptionPlans={subscriptionPlans}
      onSuccess={onSuccess}
    />
  </Modal>
);

const AddMembers = (props) => {
  const [members, setMembers] = useState([
    {
      email: "",
      role: "teacher",
    },
  ]);

  const [error, setError] = useState(null);

  useEffect(() => {
    setMembers([
      {
        email: "",
        role: "teacher",
      },
    ]);
  }, [props.isOpen]);

  const remove = (e) => {
    const memberIdx = parseInt(e.target.getAttribute("data-team-user-idx"));
    const newMembers = [...members];
    newMembers.splice(memberIdx, 1);
    setMembers(newMembers);
  };

  const onChangeProp = (e) => {
    const memberIdx = parseInt(e.target.getAttribute("data-team-user-idx"));
    const newMembers = replaceArrayItem(members, memberIdx, (member) => ({
      ...member,
      [e.target.getAttribute("name")]: e.target.value,
    }));
    setMembers(newMembers);
    validate(newMembers);
  };

  const onChangeRole = (e) => {
    const memberIdx = parseInt(e.target.getAttribute("data-team-user-idx"));
    const newRole = e.target.value;
    setMembers(
      replaceArrayItem(members, memberIdx, (member) => ({
        ...member,
        role: newRole,
        team: newRole == "manager" ? null : member.team,
      }))
    );
  };

  const addUser = (e) => {
    e.preventDefault();

    setMembers([
      ...members,
      {
        email: "",
        role: "teacher",
      },
    ]);
  };

  const validate = useCallback(
    debounce(1000, (members) => {
      const errors = [];
      for (const member of members) {
        const email = member.email.replace(/\s/g, "");

        if (email && !/\S+@\S+\.\S+/.test(email)) {
          errors.push(`${email} doesn't look like a valid e-mail address.`);
        }

        if (email && members.filter((t) => t.email == email).length > 1) {
          errors.push(`${email} is duplicated.`);
        }
      }
      if (errors.length == 0) {
        setError(null);
        return true;
      } else {
        setError(
          <div className="error-message my-2">
            {errors.map((error) => (
              <p>{error}</p>
            ))}
          </div>
        );
        return false;
      }
    }),
    []
  );

  const onSubmit = () => {
    props.onSubmit(members);
  };

  const canSubmit = members.every((m) => m.email.replace(/\s/g, "")) && !error;

  return (
    <Modal isOpen={props.isOpen} onRequestClose={props.onClose}>
      <div className="px-4 pb-4">
        <h1 className="text-primary-grey-blue-02 leading-none mb-4 text-xl">
          Add users
        </h1>
        <div className="bg-white lg:p-4">
          <div className="form-inputs white-bg-inputs">
            <div className="mt-4">
              {members.map((member, idx) => (
                <div key={`tc-${idx}`}>
                  <div className="flex">
                    <div className="input w-72 md:mr-2">
                      <input
                        data-team-user-idx={idx}
                        type="text"
                        className="w-full"
                        placeholder="User's e-mail address"
                        name="email"
                        onChange={onChangeProp}
                        value={member.email || ""}
                      />
                    </div>
                    <div className="input w-32 md:mr-2">
                      <input
                        data-team-user-idx={idx}
                        type="text"
                        className="w-full"
                        placeholder="First Name"
                        name="firstName"
                        onChange={onChangeProp}
                        value={member.firstName || ""}
                      />
                    </div>
                    <div className="input w-32 md:mr-2">
                      <input
                        data-team-user-idx={idx}
                        type="text"
                        className="w-full"
                        placeholder="Last Name"
                        name="lastName"
                        onChange={onChangeProp}
                        value={member.lastName || ""}
                      />
                    </div>
                    <div className="input w-32 md:mr-2">
                      <input
                        data-team-user-idx={idx}
                        type="text"
                        className="w-full"
                        placeholder="School"
                        name="school"
                        onChange={onChangeProp}
                        value={member.school || ""}
                      />
                    </div>
                    <div className="input w-32 md:mr-2">
                      <input
                        data-team-user-idx={idx}
                        type="text"
                        className="w-full"
                        placeholder="District"
                        name="district"
                        onChange={onChangeProp}
                        value={member.district || ""}
                      />
                    </div>
                    <div className="input md:mr-2">
                      <select
                        data-team-user-idx={idx}
                        value={member.role}
                        className="md:mr-4"
                        onChange={onChangeRole}
                      >
                        {Object.keys(ROLES).map((role) => (
                          <option key={`role-${idx}-${role}`} value={role}>
                            {ROLES[role]}
                          </option>
                        ))}
                      </select>
                    </div>
                    {members.length > 1 ? (
                      <div className="input">
                        <button
                          className="link h-full"
                          data-team-user-idx={idx}
                          onClick={remove}
                        >
                          remove
                        </button>
                      </div>
                    ) : null}
                  </div>

                  {member.error ? (
                    <div className="error-message mb-4">{member.error}</div>
                  ) : null}
                </div>
              ))}
            </div>
            <button
              className="button-link pl-0"
              onClick={addUser}
              type="button"
            >
              Add another user
            </button>
          </div>
          {error}
          <div className="flex mt-6">
            <button
              className="button-form mr-4"
              type="submit"
              disabled={!canSubmit}
              onClick={onSubmit}
            >
              Add users
            </button>
            <a className="button-form-secondary" onClick={props.onClose}>
              Cancel
            </a>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const ReviewMembers = (props) => {
  const [members, setMembers] = useState(props.members);
  const [numUsers, setNumUsers] = useState(props.numUsers);
  const [removedMembers, setRemovedMembers] = useState([]);
  const [openAddMembers, setOpenAddMembers] = useState(false);
  const [openPurchaseAccounts, setOpenPurchaseAccounts] = useState(false);
  const [modified, setModified] = useState(false);
  const [working, setWorking] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (members != props.members) {
      setModified(true);
    } else {
      setModified(false);
    }
  }, [members]);

  const closeAddMembers = () => setOpenAddMembers(false);
  const closePurchaseAccounts = () => setOpenPurchaseAccounts(false);

  const proceed = () => {
    if (
      !confirm(
        "By proceeding with the current selection of users, members will be added or removed " +
          "from your group according to your choices. Please confirm your selection or cancel " +
          "if you'd like to take another look."
      )
    ) {
      return;
    }

    setWorking(true);

    const review = {
      users: members.map((member) => ({
        email: member.email,
        first_name: member.firstName,
        last_name: member.lastName,
        school: member.school,
        district: member.district,
        role: member.role,
      })),
    };

    xhrPost(JsRoutes.review_subscriptions_path(), {
      data: {
        review,
      },
      success: (_data, response) => {
        const newLocation =
          response.headers.get("Location") || JsRoutes.root_path();
        window.location.href = newLocation;
      },
      error: (e) => {
        setWorking(false);
        const errorMessage =
          "Something went wrong when renewing the account. Please try again later, or contact us for help.";
        e.response
          .json()
          .then((data) => {
            setErrors(parseServerErrors(data, errorMessage));
          })
          .catch((e) => {
            setErrors([errorMessage]);
          });
      },
    });
  };

  const addNewMember = () => {
    setOpenAddMembers(true);
  };

  const purchaseAccounts = () => setOpenPurchaseAccounts(true);

  const selectMember = (selectedMember, selected) => {
    setMembers(
      members.map((member) => ({
        ...member,
        selected:
          selectedMember.email == member.email ? selected : member.selected,
      }))
    );
  };

  const selectAll = () => {
    const allSelected = members.every((m) => m.selected);
    setMembers(
      members.map((member) => ({
        ...member,
        selected: allSelected ? false : true,
      }))
    );
  };

  const removeMember = (member) => {
    const position = members.findIndex((m) => m.email == member.email);
    setRemovedMembers([{ member, position }]);
    setMembers(members.filter((m) => m.email != member.email));
  };

  const removeSelected = () => {
    const remainingMembers = [];
    const newlyRemoved = [];

    for (let position = 0; position < members.length; position++) {
      const member = members[position];
      if (member.selected) {
        newlyRemoved.push({ member, position });
      } else {
        remainingMembers.push(member);
      }
    }
    setRemovedMembers([...removedMembers, ...newlyRemoved]);
    setMembers(remainingMembers);
  };

  const undoRemoval = () => {
    const undoneMembers = [...members];
    for (const removedMember of sortBy(removedMembers, "position")) {
      undoneMembers.splice(removedMember.position, 0, {
        ...removedMember.member,
        selected: false,
      });
    }
    setMembers(undoneMembers);
    setRemovedMembers([]);
  };

  const undoAll = () => {
    setRemovedMembers([]);
    setMembers(props.members);
    setErrors([]);
  };

  const onAddMembers = (newMembers) => {
    const allMembers = uniqBy([...members, ...newMembers], "email");
    setMembers(allMembers);
    closeAddMembers();
  };

  const onPurchaseSuccess = (data) => {
    setNumUsers(data.num_users);
    setOpenPurchaseAccounts(false);
  };

  const getNotice = () => {
    let noticeContent = (
      <div className="font-bold">
        Your subscription has been renewed for {numUsers} users, you can now
        edit your group by adding and removing users.
      </div>
    );

    if (members.length > numUsers) {
      noticeContent = (
        <div className="font-bold">
          Your subscription has been renewed for {numUsers} users, you must
          remove a user or{" "}
          <button className="link-bold" onClick={purchaseAccounts}>
            purchase additional accounts
          </button>{" "}
          to continue.
        </div>
      );
    } else if (members.length < numUsers) {
      noticeContent = (
        <div>
          <span className="font-bold">
            You have {numUsers - members.length} available accounts.
          </span>{" "}
          <button className="link-bold" onClick={addNewMember}>
            Add new users now
          </button>{" "}
          or{" "}
          <button className="link" onClick={proceed}>
            skip and add them later
          </button>
        </div>
      );
    }

    return <div className="bg-light-grey-03 p-4 mb-6">{noticeContent}</div>;
  };

  const getRemovedNotice = () => {
    if (removedMembers.length == 1) {
      return (
        <div className="bg-light-grey-03 p-4 mb-6">
          {removedMembers[0].member.email} has been removed from your group.{" "}
          <button className="link" onClick={undoRemoval}>
            Undo
          </button>
        </div>
      );
    } else if (removedMembers.length > 1) {
      return (
        <div className="bg-light-grey-03 p-4 mb-6">
          {removedMembers.length} users have been removed from your group.{" "}
          <button className="link" onClick={undoRemoval}>
            Undo
          </button>
        </div>
      );
    } else {
      return null;
    }
  };

  const getCrudAction = () => {
    const selectedCount = members.filter((m) => m.selected).length;

    if (selectedCount > 0) {
      return (
        <button className="button-form" onClick={removeSelected}>
          Remove {selectedCount} selected
        </button>
      );
    }

    if (members.length < numUsers) {
      return (
        <button className="button-form" onClick={addNewMember}>
          Add new users
        </button>
      );
    }

    return null;
  };

  const proceedDisabled = members.length > numUsers || working;
  const parentClass = working ? "pointer-events-none opacity-50" : "";

  return (
    <div>
      <PurchaseAccountsModal
        isOpen={openPurchaseAccounts}
        onClose={closePurchaseAccounts}
        startDate={props.startDate}
        endDate={props.endDate}
        numAccounts={props.numUsers}
        groupName={props.groupName}
        subscriptionPlans={props.subscriptionPlans}
        onSuccess={onPurchaseSuccess}
      />
      <div className="flex y-padded-top pb-4 x-padded justify-between">
        <h1 className="grid-title leading-none">Review Group Members</h1>
        <div className="flex">
          {modified ? (
            <button className="button-form-secondary mr-2" onClick={undoAll}>
              Restart review
            </button>
          ) : null}
          <button
            className="button-primary"
            disabled={proceedDisabled}
            onClick={proceed}
          >
            Proceed with current group
          </button>
        </div>
      </div>
      {errors ? (
        <div className="my-4">
          {errors.map((error, idx) => (
            <FieldError key={`error-${idx}`}>{error}</FieldError>
          ))}
        </div>
      ) : null}
      <div
        className={`max-w-admin-container overflow-auto bg-white ${parentClass}`}
      >
        <div className="bg-white p-4">
          {getNotice()}
          {getRemovedNotice()}
          <div className="flex justify-between">
            <div>
              <span
                className={`text-2xl font-bold ${
                  numUsers < members.length ? "text-red-pinkish" : null
                }`}
              >
                {members.length}/{numUsers}
              </span>
              &nbsp;
              <span
                className={
                  numUsers < members.length ? "text-red-pinkish" : null
                }
              >
                Accounts used
              </span>
              &nbsp;|&nbsp;
              <button className="link" onClick={purchaseAccounts}>
                purchase additional accounts
              </button>
            </div>
            <div className="flex">{getCrudAction()}</div>
          </div>
        </div>
        <table className="admin-grid w-full medium">
          <thead>
            <tr>
              <th>Email</th>
              <th>First name</th>
              <th>Last name</th>
              <th>School</th>
              <th>District</th>
              <th>Role</th>
              <th>select all</th>
              <th>
                <input
                  type="checkbox"
                  onChange={selectAll}
                  checked={members.length && members.every((m) => m.selected)}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member.email}>
                <td>{member.email}</td>
                <td>{member.firstName}</td>
                <td>{member.lastName}</td>
                <td>{member.school}</td>
                <td>{member.district}</td>
                <td>{member.role}</td>
                <td>
                  <button className="link" onClick={() => removeMember(member)}>
                    remove
                  </button>
                </td>
                <td>
                  <input
                    type="checkbox"
                    onChange={(e) => selectMember(member, e.target.checked)}
                    checked={member.selected ? true : false}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <AddMembers
        isOpen={openAddMembers}
        onClose={closeAddMembers}
        onSubmit={onAddMembers}
      />
    </div>
  );
};

export default ReviewMembers;
