import * as JsRoutes from "./util/routes";
import braintree from "braintree-web";

export const braintreeClient = () =>
  fetch(JsRoutes.generate_braintree_token_path(), {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
  })
    .then((r) => r.json())
    .then(({ token }) =>
      braintree.client.create({
        authorization: token,
      })
    );

export const braintreeHostedFields = () =>
 new Promise((resolve, reject) => {
  braintreeClient().then((client) =>
    braintree.hostedFields.create({
      client,
      styles: {
        input: "braintree-input",
        ".invalid": "braintree-input--invalid",
        ".valid": "braintree-input--valid",
      },
      fields: {
        number: {
          selector: "#card-number",
          placeholder: "•••• •••• •••• ••••",
        },
        cvv: {
          selector: "#cvv",
          placeholder: "•••",
        },
        expirationDate: {
          selector: "#expiration-date",
          placeholder: "MM/YYYY",
        },
        postalCode: {
          selector: "#postal-code",
          minlength: 5,
        },
      },
    }, (err, instance) => {
      if (err) {
        reject(err);
      } else {
        resolve(instance);
      }
    })
  );
 });
