import React, { useState } from "react";
import * as JsRoutes from "../util/routes";
const ROLES = ["manager", "coach", "teacher"];
import upperFirst from "lodash/upperFirst";
import getMetaValue from "../util/getMetaValue";
import replaceArrayItem from "../util/replaceArrayItem";

const UpdateMember = ({ member, teams }) => {
  const [role, setRole] = useState(member.role);
  const [memberships, setMemberships] = useState(member.teamMemberships);

  const onChangeRole = (e) => setRole(e.target.value);
  const onCheckTeam = (e) => {
    const newTeamIds = new Set(memberships.map((m) => m.teamId));
    const newValue = parseInt(e.target.value);
    if (e.target.checked) {
      newTeamIds.add(newValue);
    } else {
      newTeamIds.delete(newValue);
    }

    setMemberships(
      Array.from(newTeamIds).map((teamId) => ({
        teamId,
      }))
    );
  };

  const isCoach = role === "coach";
  const isTeacher = role === "teacher";

  return (
    <form
      className="form-inputs"
      method="POST"
      action={JsRoutes.member_path(member.id)}
    >
      <input
        type="hidden"
        name="authenticity_token"
        value={getMetaValue("csrf-token")}
      />
      <input type="hidden" name="_method" value="patch" />
      <div className="input">
        <label htmlFor="role">Role</label>
        <select
          name="member[roles][]"
          id="role"
          onChange={onChangeRole}
          value={role}
        >
          {ROLES.map((role) => (
            <option key={`role-${role}`} value={role}>
              {upperFirst(role)}
            </option>
          ))}
        </select>
      </div>
      {isTeacher ? (
        <div className="input">
          <label htmlFor="team">Team</label>
          <select name="member[team_ids][]" id="team">
            <option value="">No team</option>
            {teams.map((team) => (
              <option
                key={`teacher-team-${team.id}`}
                value={team.id}
                selected={memberships.find((m) => m.teamId == team.id)}
              >
                {team.name}
              </option>
            ))}
          </select>
        </div>
      ) : null}
      {isCoach ? (
        <div className="input">
          <label>Teams</label>
          <div className="pl-2">
            {teams.map((team) => (
              <div key={`coach-team-${team.id}`}>
                <input
                  id={`coach-team-${team.id}`}
                  className="mr-2"
                  name="member[team_ids][]"
                  type="checkbox"
                  value={team.id}
                  checked={
                    memberships.find((m) => m.teamId == team.id) ? true : false
                  }
                  onChange={onCheckTeam}
                />
                <label htmlFor={`coach-team-${team.id}`}>{team.name}</label>
              </div>
            ))}
          </div>
        </div>
      ) : null}

      <div className="form-actions">
        <button type="submit" className="button-form md:mr-2">
          Update Member
        </button>
        <a
          href={JsRoutes.member_path(member.id)}
          className="button-form-secondary"
        >
          Cancel
        </a>
      </div>
    </form>
  );
};

export default UpdateMember;
