import React, { Fragment } from "react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import { action } from "mobx";
import { observer } from "mobx-react";
import AudioPlayer from "./AudioPlayer";

@observer
class PracticeWriteAudioExemplar extends TrainingComponentEditor {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.trainingComponent.addFile(0, this.fileRef);
  }

  initializeData() {
    return {
      prompt: "",
      exemplar: "",
      title: ""
    };
  }

  @action.bound
  handlePromptChange(evt) {
    this.data.prompt = evt.target.value;
  }

  @action.bound
  handleExemplarChange(evt) {
    this.data.exemplar = evt.target.value;
  }

  render() {
    return (
      <Fragment>
        <FormGroup name="prompt" label="Prompt">
          {(formControl) => (
            <textarea
              {...formControl}
              value={this.data.prompt}
              onChange={this.handlePromptChange}
            />
          )}
        </FormGroup>
        <FormGroup name="exemplar" label="Exemplar">
          {(formControl) => (
            <textarea
              {...formControl}
              value={this.data.exemplar}
              onChange={this.handleExemplarChange}
              placeholder="Exemplar"
            />
          )}
        </FormGroup>
        <FormGroup name="audio" label="Title">
          {(formControl) => (
            <input
              {...formControl}
              name="title"
              type="text"
              className="form-control u-margin-top"
              placeholder="Title"
              value={this.data.title}
              onChange={(e) => this.updateData({ title: e.target.value })}
            />
          )}
        </FormGroup>
        <FormGroup name="url" label="Audio File">
          {(formControl) => (
            <Fragment>
              {this.buildFileInput(0, {
                ...formControl,
                accept: ".mp3",
              })}
              <AudioPlayer
                trainingComponent={this.trainingComponent}
              />
            </Fragment>
          )}
        </FormGroup>
      </Fragment>
    );
  }
}

export default PracticeWriteAudioExemplar;
