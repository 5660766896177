import React from "react";
import { useForm } from "react-hook-form";
import { MAIL_FORMAT } from "../../../patterns";
import FieldError from "../../FieldError";

const EmailApprovalForm = ({ value, onSubmit, onCancel }) => {
  const { errors, handleSubmit, register } = useForm({
    defaultValues: {
      fullname: value?.fullname,
      ed_prep_program: value?.ed_prep_program,
      instructor_name: value?.ed_prep_instructor_name,
      instructor_email: value?.ed_prep_instructor_email,
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <div className="flex">
        <div className="w-3/5">
          <h3 className="text-lg leading-none my-6 font-bold">
            Verify Account
          </h3>
          <p>
            We need to verify that you are enrolled in a Texas Ed Prep Program
            before you can create an account. Complete the following:
          </p>
          <div className="form-inputs white-bg-inputs">
            <div className="input">
              <label>Full Name</label>
              <input
                className="w-full"
                autoComplete="fullname"
                autoFocus="autofocus"
                type="text"
                name="fullname"
                ref={register({
                  required: "is required",
                  minLength: {
                    value: 2,
                    message: "should be longer than 1 character",
                  },
                })}
              />
              <FieldError>{errors.fullname?.message}</FieldError>
            </div>
          </div>
          <div className="form-inputs white-bg-inputs">
            <div className="input">
              <label>Ed. Prep. Program</label>
              <input
                className="w-full"
                autoComplete="ed_prep_program"
                type="text"
                name="ed_prep_program"
                ref={register({
                  required: "is required",
                  minLength: {
                    value: 2,
                    message: "should be longer than 1 character",
                  },
                })}
              />
              <FieldError>{errors.ed_prep_program?.message}</FieldError>
            </div>
          </div>
          <div className="form-inputs white-bg-inputs">
            <div className="input">
              <label>Ed. Prep. Program Instructor Name</label>
              <input
                className="w-full"
                autoComplete="instructor_name"
                type="text"
                name="instructor_name"
                ref={register({
                  required: "is required",
                  minLength: {
                    value: 2,
                    message: "should be longer than 1 character",
                  },
                })}
              />
              <FieldError>{errors.instuctor_name?.message}</FieldError>
            </div>
          </div>
          <div className="form-inputs white-bg-inputs">
            <div className="input">
              <label>Ed. Prep. Program Instructor Email</label>
              <input
                className="w-full"
                autoComplete="instructor_email"
                type="email"
                name="instructor_email"
                ref={register({
                  required: "is required",
                  pattern: { value: MAIL_FORMAT, message: "is invalid" },
                })}
              />
              <FieldError>{errors.instructor_email?.message}</FieldError>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <button className="button-form mr-4" onClick={onCancel}>
          Back
        </button>
        <button className="button-primary" type="submit">
          Send Verification Request
        </button>
      </div>
    </form>
  );
};

export default EmailApprovalForm;
