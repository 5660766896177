import React, { Fragment, useEffect, useState } from "react";

const validate = (subscriptionDetails) => {
  if (!subscriptionDetails.plan) {
    if (subscriptionDetails.group) {
      return "Please type in the number of accounts and group name.";
    }
    return "Please select a subscription plan.";
  } else if (subscriptionDetails.group) {
    if (!subscriptionDetails.groupName || !subscriptionDetails.accounts)
      return "Please type in the number of accounts and group name.";
  }
  return undefined;
};

const SubscriptionPlans = ({ value, subscriptionPlans, onChange }) => {
  const [isGroup, setIsGroup] = useState(value?.group || false);
  const [groupName, setGroupName] = useState(value?.groupName || "");
  const [plan, setPlan] = useState(value?.plan);
  const [accounts, setAccounts] = useState(value?.accounts || "");
  const [subscriptionDetails, setSubscriptionDetails] = useState(value);

  useEffect(() => {
    const newSubscriptionDetails = {
      group: isGroup,
      groupName: groupName ? groupName : null,
      plan,
      accounts: accounts ? parseInt(accounts) : null,
    };
    newSubscriptionDetails.errorMessage = validate(newSubscriptionDetails);
    setSubscriptionDetails(newSubscriptionDetails);
  }, [isGroup, groupName, plan, accounts]);

  useEffect(() => {
    onChange(subscriptionDetails);
  }, [subscriptionDetails]);

  const handleChangeGroup = (e) => {
    setIsGroup(e.target.value == "true" && e.target.checked);
    setPlan(null);
    setGroupName("");
    setAccounts("");
  };
  const handleChangeGroupName = (e) => setGroupName(e.target.value);
  const handleChangePlan = (e) =>
    setPlan(
      subscriptionPlans.find((plan) => plan.id.toString() == e.target.value) ||
        null
    );
  const handleChangeAccounts = (e) => {
    const newAccounts = parseInt(e.target.value);
    setAccounts(newAccounts);
    const newPlan = subscriptionPlans.find((p) => {
      return p.min <= newAccounts && p.max >= newAccounts;
    });
    setPlan(newPlan || "");
  };

  const planTitle = (pl) => {
    if (pl.group) {
      return `${pl.name}: $${parseInt(pl.price)} (per user) x ${accounts} = $${
        accounts * pl.price
      }`;
    }

    return `${pl.name} - ($${parseFloat(pl.price).toFixed(2)})`;
  };

  return (
    <div className="form-inputs">
      <div id="subscriptionPlanPicker" onChange={handleChangeGroup}>
        <div className="my-6 radio_boxes flex">
          <div className="radiobox mr-8">
            <input
              type="radio"
              id="group-false"
              name="group"
              value="false"
              checked={!isGroup}
              onChange={handleChangeGroup}
            />
            <label htmlFor="group-false">
              A single account for an individual
            </label>
          </div>
          <div className="radiobox">
            <input
              type="radio"
              id="group-true"
              name="group"
              value="true"
              checked={isGroup}
              onChange={handleChangeGroup}
            />
            <label htmlFor="group-true">Multiple accounts for your group</label>
          </div>
        </div>
      </div>

      {isGroup ? (
        <Fragment>
          <div className="inline-input">
            <label htmlFor="quantity" className="w-1/4">
              Number of accounts
            </label>
            <input
              className="w-1/4"
              type="number"
              step="1"
              id="quantity"
              min="1"
              value={accounts}
              onChange={handleChangeAccounts}
            />
          </div>
          <div className="inline-input">
            <label htmlFor="groupName" className="w-1/4">
              Your group's name:<small>*</small>
            </label>
            <input
              className="w-3/4"
              type="text"
              id="groupName"
              aria-describedby="groupNameHelpBlock"
              placeholder="A name to help you identify the group. We suggest using the school name."
              value={groupName}
              onChange={handleChangeGroupName}
            />
          </div>
        </Fragment>
      ) : (
        <div className="input">
          <select onChange={handleChangePlan} value={plan ? plan.id : ""}>
            <option value="" disabled>
              Select a plan
            </option>
            {subscriptionPlans
              .filter((pl) => !pl.group)
              .map((pl) => (
                <option key={`plan-${pl.id}`} value={pl.id}>
                  {planTitle(pl)}
                </option>
              ))}
          </select>
        </div>
      )}

      {plan ? (
        <div>
          <div>
            <h3 className="text-sm font-bold leading-none mb-2">
              {planTitle(plan)}
            </h3>
            <p>{plan.description}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SubscriptionPlans;
