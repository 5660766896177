import React from "react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import { action } from "mobx";
import { observer } from "mobx-react";

@observer
class Text extends TrainingComponentEditor {
  initializeData() {
    return { text: "" };
  }

  @action.bound
  handleTextChange(evt) {
    this.data.text = evt.target.value;
  }

  render() {
    return (
      <FormGroup name="text" label="Text">
        {(formControl) => (
          <textarea
            {...formControl}
            value={this.data.text}
            onChange={this.handleTextChange}
          />
        )}
      </FormGroup>
    );
  }
}

export default Text;
