import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

const Table = ({ trainingComponent }) => (
  // prevent </br> tags from breaking the rendering
  <ReactMarkdown
    rehypePlugins={[rehypeRaw, rehypeSanitize]}
    remarkPlugins={[remarkGfm]}
  >
    {(trainingComponent.data.text || "").replace(/\<\/\s?br>/g, "<br/>")}
  </ReactMarkdown>
);

Table.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Table;
