import React from "react";
import PropTypes from "prop-types";
import VideoPlayer from "./VideoPlayer";

const Video = ({ trainingComponent }) => {
  const data = trainingComponent.data;

  if (!data.videos) {
    return null;
  }

  const videos = data.videos.filter((v) => v.url);

  if (!videos.length) {
    return null;
  }

  if (videos.length == 1) {
    if (data.proportion == 100) {
      return <VideoPlayer video={videos[0]} />;
    } else {
      return (
        <div className="w-2/3">
          <VideoPlayer video={videos[0]} />
        </div>
      );
    }
  } else {
    return (
      <div className="flex flex-wrap lg:flex-nowrap">
        <div className="md:w-1/2">
          <VideoPlayer video={videos[0]} />
        </div>
        <div className="md:w-1/2">
          <VideoPlayer video={videos[1]} />
        </div>
      </div>
    );
  }
};

Video.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Video;
