import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";
import PracticeRecord from "./PracticeRecord";

class PracticeRecordReview extends PracticeRecord {
  render() {
    return (
      <div className="flex flex-wrap lg:flex-nowrap pb-2">
        <div className="md:w-1/2">
          <Markdown>{this.props.trainingComponent.data.text}</Markdown>
        </div>
        <div className="w-full md:w-1/2 text-center md:text-right">
          {this.cameraTag()}
        </div>
      </div>
    );
  }
}

PracticeRecordReview.propTypes = {
  training: PropTypes.object,
  trainingComponent: PropTypes.object,
  config: PropTypes.object,
};

export default PracticeRecordReview;
