import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { action } from "mobx";
import { observer } from "mobx-react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import VideoPlayer from "./VideoPlayer";

@observer
class VideoItem extends Component {
  @action.bound
  onChangeUrl(event) {
    this.props.video.url = event.target.value;
  }

  @action.bound
  onChangeTitle(event) {
    this.props.video.title = event.target.value;
  }

  @action.bound
  onDelete(event) {
    event.preventDefault();
    this.props.onDelete(this.props.video);
  }

  render() {
    return (
      <div className="flex mb-4">
        <div className="w-1/2">
          <FormGroup name="title" label="Video Title">
            {(formControl) => (
              <input
                {...formControl}
                value={this.props.video.title || ""}
                onChange={this.onChangeTitle}
              />
            )}
          </FormGroup>
          <FormGroup name="url" label="Video URL">
            {(formControl) => (
              <Fragment>
                <input
                  {...formControl}
                  type="text"
                  value={this.props.video.url}
                  placeholder="Video URL"
                  onChange={this.onChangeUrl}
                />
                <button className="button-danger mt-2" onClick={this.onDelete}>
                  Remove
                </button>
              </Fragment>
            )}
          </FormGroup>
        </div>
        <VideoPlayer video={this.props.video} className="w-1/2" />
      </div>
    );
  }
}

VideoItem.propTypes = {
  video: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
};

@observer
class VideoGallery extends TrainingComponentEditor {
  initializeData() {
    return {
      videos: [{ url: "", title: "" }],
    };
  }

  @action.bound
  ondAddVideo(event) {
    event.preventDefault();
    this.data.videos.push({ url: "", title: "" });
  }

  @action.bound
  onDelete(video) {
    this.data.videos.remove(video);
  }

  render() {
    return (
      <Fragment>
        {this.data.videos.map((video, idx) => (
          <VideoItem
            key={`${this.props.trainingComponent.id}-videoitem-${idx}`}
            video={video}
            onDelete={this.onDelete}
          />
        ))}
        <button className="button-form" onClick={this.ondAddVideo}>
          Add video
        </button>
      </Fragment>
    );
  }
}

export default VideoGallery;
