import React, { useState } from "react";
import PaymentForm from "./signup/tlaconline/PaymentForm";
import xhrPost from "../util/xhrPost";
import * as JsRoutes from "../util/routes";
import FieldError from "./FieldError";
import parseServerErrors from "../util/parseServerErrors";

const PurchaseAccounts = ({
  groupName,
  numAccounts,
  startDate,
  endDate,
  onCancel,
  subscriptionPlans,
  onSuccess,
  fromDashboard = false,
  title = "Add users to your account",
}) => {
  const [working, setWorking] = useState(false);
  const [errors, setErrors] = useState([]);

  const handleChangePayment = ({ payment, subscriptionPlan }) => {
    setWorking(true);

    xhrPost(JsRoutes.upgrade_subscriptions_path(), {
      data: {
        subscription_plan: {
          id: subscriptionPlan.plan.id,
          group_name: subscriptionPlan.groupName,
          accounts: subscriptionPlan.accounts,
        },
        transaction: {
          pay_by: payment.pay_by,
          coupon: payment.coupon,
          nonce: payment.nonce,
          invoice_address: payment.invoice_address,
        },
      },
      success: (data, response) => {
        setWorking(false);
        onSuccess(
          { ...data, newAccounts: subscriptionPlan.accounts },
          response
        );
      },
      error: (e) => {
        setWorking(false);
        const errorMessage =
          "Something went wrong when creating your account. Please try again later, or contact us for help.";
        e.response
          .json()
          .then((data) => {
            setErrors(parseServerErrors(data, errorMessage));
          })
          .catch((e) => {
            setErrors([errorMessage]);
          });
      },
    });
  };

  const paymentAccount = {
    isGroup: true,
    groupName: groupName,
  };

  const upgrade = {
    type: "addUsers",
    numAccounts,
    startDate,
    endDate,
    fromDashboard,
  };

  return (
    <div className="px-3-5rem pb-12 text-primary-grey-blue md:w-56rem text-sm">
      <h1 className="leading-none text-primary-grey-blue-02 mb-12">{title}</h1>
      <div className="py-6 px-8 bg-white">
        <PaymentForm
          account={paymentAccount}
          onCancel={onCancel}
          onSubmit={handleChangePayment}
          disabled={working}
          subscriptionPlans={subscriptionPlans}
          upgrade={upgrade}
        />
        {errors.map((error, idx) => (
          <FieldError key={`error-${idx}`}>{error}</FieldError>
        ))}
      </div>
    </div>
  );
};

export default PurchaseAccounts;
