import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import practices from "../../../stores/practices";

@observer
class Review extends Component {
  render() {
    const videos = practices.videos();
    const submittedVideos = [];

    for (let i = 0; i < videos.length; i++) {
      const video = videos[i];
      if (video.active) {
        submittedVideos.push({ round: i + 1, video });
      }
    }

    if (!submittedVideos.length) {
      return null;
    }

    return (
      <div>
        {submittedVideos.map(({ round, video }) => {
          let title = `Round ${round}`;

          if (video.reviewTitle) {
            title += `: ${video.reviewTitle}`;
          }

          return (
            <div className="mb-4" key={`video-${video.id}`}>
              <h1>{title}</h1>
              <video
                width="100%"
                src={`${video.url}?${video.reloadAt || ""}`}
                controls={true}
                preload="metadata"
              />
            </div>
          );
        })}
      </div>
    );
  }
}

Review.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Review;
