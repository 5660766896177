import uniq from "lodash/uniq";

const extractEmails = (str) => {
  str = str
    .replace(/\s/g, "")
    .split(",")
    .filter((e) => e);

  const emails = [];
  const errors = [];

  for (var i = 0, j = str.length; i < j; i++) {
    const address = str[i];
    if (/\S+@\S+\.\S+/.test(address)) {
      emails.push(address);
    } else {
      errors.push(address);
    }
  }

  return { emails: uniq(emails.sort()), errors };
};

export default extractEmails;
