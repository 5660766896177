import PropTypes from "prop-types";
import React from "react";
import Alert from "./types/Alert";
import Audio from "./types/Audio";
import Chapter from "./types/Chapter";
import Closing from "./types/Closing";
import Image from "./types/Image";
import Materials from "./types/Materials";
import PracticeRecordReview from "./types/PracticeRecordReview";
import PracticeTchart from "./types/PracticeTchart";
import PracticeWrite from "./types/PracticeWrite";
import PracticeWriteAudioExemplar from "./types/PracticeWriteAudioExemplar";
import PracticeWriteExemplar from "./types/PracticeWriteExemplar";
import PracticeWriteRecord from "./types/PracticeWriteRecord";
import PracticeWriteRecordExemplar from "./types/PracticeWriteRecordExemplar";
import Review from "./types/Review";
import Table from "./types/Table";
import Text from "./types/Text";
import TextAndImage from "./types/TextAndImage";
import Video from "./types/Video";
import VideoGallery from "./types/VideoGallery";

const getViewer = (trainingComponent) => {
  switch (trainingComponent.type) {
    case "TrainingComponents::Alert":
      return Alert;
    case "TrainingComponents::Audio":
      return Audio;
    case "TrainingComponents::Chapter":
      return Chapter;
    case "TrainingComponents::Image":
      return Image;
    case "TrainingComponents::Materials":
      return Materials;
    case "TrainingComponents::Table":
      return Table;
    case "TrainingComponents::Text":
      return Text;
    case "TrainingComponents::TextAndImage":
      return TextAndImage;
    case "TrainingComponents::PracticeRecordReview":
      return PracticeRecordReview;
    case "TrainingComponents::PracticeTchart":
      return PracticeTchart;
    case "TrainingComponents::PracticeWrite":
      return PracticeWrite;
    case "TrainingComponents::PracticeWriteExemplar":
      return PracticeWriteExemplar;
    case "TrainingComponents::PracticeWriteAudioExemplar":
      return PracticeWriteAudioExemplar;
    case "TrainingComponents::PracticeWriteRecord":
      return PracticeWriteRecord;
    case "TrainingComponents::PracticeWriteRecordExemplar":
      return PracticeWriteRecordExemplar;
    case "TrainingComponents::Review":
      return Review;
    case "TrainingComponents::Video":
      return Video;
    case "TrainingComponents::VideoGallery":
      return VideoGallery;
    case "TrainingComponents::Closing":
      return Closing;
    default:
      return null;
  }
};

const TrainingComponent = ({
  training,
  trainingComponent,
  trainingProgress,
  config,
  previewMode,
}) => {
  const ComponentViewer = getViewer(trainingComponent);

  const componentContainerClass =
    trainingComponent.type === "TrainingComponents::Alert"
      ? "training-component-container-long"
      : "training-component-container";

  return (
    <div
      className={`training-component ${componentContainerClass}`}
      data-type={trainingComponent.type.split("::")[1]}
    >
      {ComponentViewer ? (
        <ComponentViewer
          training={training}
          trainingComponent={trainingComponent}
          trainingProgress={trainingProgress}
          config={config}
          previewMode={previewMode}
        />
      ) : null}
    </div>
  );
};

TrainingComponent.propTypes = {
  training: PropTypes.object,
  trainingComponent: PropTypes.object,
  config: PropTypes.object,
  previewMode: PropTypes.bool,
};

export default TrainingComponent;
