import React, { useEffect, useState } from "react";
import * as JsRoutes from "../../../util/routes";

const TrainingCompleteOptions = ({ title }) => {
  const [nextModule, setNextModule] = useState(null);

  useEffect(() => {
    fetchNextModule();
  }, []);

  const fetchNextModule = () => {
    fetch(JsRoutes.next_training_path())
      .then((r) => r.json())
      .then((nextModule) => {
        setNextModule(nextModule);
      });
  };

  const handleNextModule = () => {
    const newLocation = JsRoutes.training_path(nextModule.id);
    window.location.href = newLocation;
  };

  return (
    <div className="text-center p-10 bg-white">
      {title}
      <button
        className="button-primary mt-8"
        onClick={handleNextModule}
        disable={nextModule ? "" : "disable"}
      >
        Next Module
      </button>
      <p className="mt-4 font-bold">Or</p>
      <p className="mt-4">
        <a className="underline" href={JsRoutes.my_progress_path()}>
          Take me to My Progress
        </a>
      </p>
    </div>
  );
};

export default TrainingCompleteOptions;
