import { action } from "mobx";
import PropTypes from "prop-types";
import React from "react";
import practices from "../../../stores/practices";
import Camera from "./Camera";
import Practice from "./Practice";

class PracticeRecord extends Practice {
  practiceType = "video"; // default

  get cameraTagProps() {
    const user = this.props.config.user || {
      id: null,
      name: null,
    };

    return {
      ...this.props.config.cameraTag,
      metadata: {
        title: this.props.training.name,
        training_name: this.props.training.name,
        training_id: this.props.training.id,
        index: this.props.trainingComponent.order,
        user_id: user.id,
        user_name: user.name,
        session: this.props.config.session,
      },
    };
  }

  @action.bound
  addPractice() {
    const existingPractice = this.findExistingPractice();
    const newPractice = existingPractice
      ? {
          ...existingPractice.content,
          practiceType: this.practiceType,
          reviewTitle: this.props.trainingComponent.data.reviewTitle,
          active: true,
        }
      : {
          practiceType: this.practiceType,
          reviewTitle: this.props.trainingComponent.data.reviewTitle,
        };
    practices.add(this.practiceId, newPractice);
  }

  @action.bound
  reloadPractice() {
    const currentPractice = practices.getById(this.practiceId);
    practices.update(this.practiceId, {
      ...currentPractice,
      reloadAt: new Date().getTime(),
    });
  }

  cameraTag() {
    return (
      <div className="pb-2">
        <Camera
          onAccept={this.updatePractice}
          onProcessed={this.reloadPractice}
          {...this.cameraTagProps}
        />
      </div>
    );
  }
}

PracticeRecord.propTypes = {
  training: PropTypes.object,
  trainingComponent: PropTypes.object,
  config: PropTypes.object,
};

export default PracticeRecord;
