import { action } from "mobx";
import PropTypes from "prop-types";
import React from "react";
import { debounce } from "throttle-debounce";
import Practice from "./Practice";

class PracticeWrite extends Practice {
  constructor(props) {
    super(props);
    this.updatePractice = debounce(200, this.updatePractice);
  }

  @action.bound
  handleTextChange(e) {
    this.updatePractice({ content: e.target.value });
  }

  render() {
    const id = this.props.trainingComponent.id;
    return (
      <textarea
        className="form-control"
        name={`component[${id}][data]`}
        aria-label="Script your question here"
        style={{ height: "6rem" }}
        placeholder="Start typing..."
        onChange={this.handleTextChange}
        defaultValue={this.defaultValue}
      />
    );
  }
}

PracticeWrite.propTypes = {
  trainingComponent: PropTypes.object,
};

export default PracticeWrite;
