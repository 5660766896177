import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import Practice from "./Practice";

@observer
class PracticeWriteExemplar extends Practice {
  @observable mode = "prompt";

  @action.bound
  onSubmit(e) {
    e.preventDefault();
    this.mode = "exemplar";
    this.updatePractice({
      content: this.input.value,
      el: this.input,
    });
  }

  get ariaTextareaLabel() {
    if (this.mode == "prompt") {
      return "Plan your action in this text area";
    } else {
      return "Compare our suggestion to your plan";
    }
  }

  render() {
    const data = this.props.trainingComponent.data;
    const id = this.props.trainingComponent.id;
    return (
      <div>
        <Markdown>{data.prompt}</Markdown>
        <form action={`#PracticeWriteExemplar-${id}`} onSubmit={this.onSubmit}>
          <textarea
            ref={(e) => (this.input = e)}
            style={{ height: "6rem" }}
            aria-label={this.ariaTextareaLabel}
            placeholder="Start typing..."
            defaultValue={this.defaultValue}
            required
          />
          <div className="mt-2">
            {this.mode === "prompt" ? (
              <button type="submit" className="button-form-secondary">
                Next...
              </button>
            ) : (
              <Markdown>{data.exemplar}</Markdown>
            )}
          </div>
        </form>
      </div>
    );
  }
}

PracticeWriteExemplar.propTypes = {
  trainingComponent: PropTypes.object,
};

export default PracticeWriteExemplar;
