import getMetaValue from "./getMetaValue";

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    var error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export default function xhrPost(url, opts) {
  let headers = {
    Accept: "application/json",
    "X-CSRF-Token": getMetaValue("csrf-token"),
  };

  var body = opts.body;
  if (opts.data) {
    headers["Content-Type"] = "application/json";
    body = JSON.stringify(opts.data);
  }

  let response;
  fetch(url, {
    method: "POST",
    headers: headers,
    body: body,
  })
    .then(checkStatus)
    .then((r) => {
      response = r;
      return r.json();
    })
    .then((data) => {
      if (opts.success) opts.success(data, response);
    })
    .catch((e) => {
      if (opts.error) opts.error(e);
    });
}
