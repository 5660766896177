import React, { Component } from "react";
import PropTypes from "prop-types";
import { computed, action } from "mobx";
import guid from "../../../util/guid";
import { observer } from "mobx-react";

class TrainingComponentEditor extends Component {
  htmlId = guid();

  constructor(props) {
    super(props);
    this.props.trainingComponent.data =
      this.props.trainingComponent.data || this.initializeData();
  }

  @computed get trainingComponent() {
    return this.props.trainingComponent;
  }

  @computed get data() {
    return this.props.trainingComponent.data;
  }

  updateData(newData) {
    this.props.trainingComponent.data = newData;
  }

  @action.bound
  buildFileInput(attachmentIndex = 0, attrs) {
    const ref = React.createRef();
    this.trainingComponent.addFile(attachmentIndex, ref);

    return (
      <input
        {...attrs}
        className="form-control-file"
        type="file"
        ref={ref}
        key={guid()}
      />
    );
  }

  @action.bound
  withHtmlId(prefix) {
    return `${prefix}-${this.htmlId}`;
  }
}

TrainingComponentEditor.propTypes = {
  trainingComponent: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
};

@observer
class FormGroup extends Component {
  htmlId = guid();

  render() {
    return (
      <div className={`input ${this.props.className}`}>
        <label htmlFor={this.htmlId}>{this.props.label}</label>
        {this.props.children({
          id: this.htmlId,
          name: this.props.name,
        })}
      </div>
    );
  }
}

FormGroup.propTypes = {
  children: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export { FormGroup };
export default TrainingComponentEditor;
