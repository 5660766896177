import React from "react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import { action } from "mobx";
import { observer } from "mobx-react";

@observer
class PracticeWriteRecord extends TrainingComponentEditor {
  initializeData() {
    return { text: "" };
  }

  @action.bound
  handleTextChange(evt) {
    this.data.text = evt.target.value;
  }

  @action.bound
  handleReviewTitleChange(event) {
    this.data.reviewTitle = event.target.value;
  }

  render() {
    return (
      <div>
        <FormGroup
          name="prompt"
          label="Prompt to appear when recording the video"
        >
          {(formControl) => (
            <textarea
              {...formControl}
              value={this.data.text}
              onChange={this.handleTextChange}
            />
          )}
        </FormGroup>
        <FormGroup
          name="reviewTitle"
          label="Prompt to appear when reviewing the video"
        >
          {(formControl) => (
            <input
              {...formControl}
              onChange={this.handleReviewTitleChange}
              value={this.data.reviewTitle}
            />
          )}
        </FormGroup>
      </div>
    );
  }
}

export default PracticeWriteRecord;
