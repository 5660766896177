import React, { Fragment } from "react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import { action, computed } from "mobx";
import { observer } from "mobx-react";
import Thumbnail from "../Thumbnail";

@observer
class TextAndImage extends TrainingComponentEditor {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.trainingComponent.addFile(0, this.fileRef);
  }

  initializeData() {
    return {
      text: "",
      imagePosition: "left",
      altText: "",
    };
  }

  @action.bound
  handleTextChange(evt) {
    this.data.text = evt.target.value;
  }

  @action.bound
  handleImagePositionChange(evt) {
    this.data.imagePosition = evt.target.value;
  }

  @computed get thumbnail() {
    return this.trainingComponent.attachments.length ? (
      <Thumbnail src={this.trainingComponent.attachments[0].thumbnailUrl} />
    ) : null;
  }

  @action.bound
  handleAltTextChange(evt) {
    this.data.altText = evt.target.value;
  }

  render() {
    return (
      <Fragment>
        <FormGroup name="image-position" label="Image Position">
          {(formControl) => (
            <select
              {...formControl}
              value={this.data.imagePosition}
              onChange={this.handleImagePositionChange}
            >
              <option value="left">Left</option>
              <option value="right">Right</option>
            </select>
          )}
        </FormGroup>
        <FormGroup name="text" label="Text">
          {(formControl) => (
            <textarea
              {...formControl}
              value={this.data.text}
              onChange={this.handleTextChange}
            />
          )}
        </FormGroup>
        <FormGroup name="file" label="Image File">
          {(formControl) => (
            <Fragment>
              {this.thumbnail}
              {this.buildFileInput(0, formControl)}
              <input
                name="alt_text"
                type="text"
                className="form-control u-margin-top"
                placeholder="Alt text"
                value={this.data.altText}
                onChange={this.handleAltTextChange}
              />
            </Fragment>
          )}
        </FormGroup>
      </Fragment>
    );
  }
}

export default TextAndImage;
