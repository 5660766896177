const parseServerErrors = (
  errorResponse,
  defaultError = "Something went wrong."
) => {
  if (errorResponse.error) {
    return [errorResponse.error];
  } else if (errorResponse.errors) {
    if (Array.isArray(errorResponse.errors)) {
      return errorResponse.errors;
    } else {
      const errors = [];
      Object.keys(errorResponse.errors).forEach((k) => {
        const errorTitle = k.charAt(0).toUpperCase() + k.slice(1);
        errorResponse.errors[k].forEach((v) =>
          errors.push(`${errorTitle} ${v}.`)
        );
      });
      return errors;
    }
  }
  return [defaultError];
};

export default parseServerErrors;
