import React, { Fragment } from "react";
import { action } from "mobx";
import { observer } from "mobx-react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import AudioPlayer from "./AudioPlayer";

@observer
class Audio extends TrainingComponentEditor {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.trainingComponent.addFile(0, this.fileRef);
  }

  initializeData() {
    return {
      title: "",
    };
  }

  @action.bound
  onChangeFile(event) {
    this.trainingComponent.attachments = [event.target.files[0]];
  }

  render() {
    return (
      <Fragment>
        <FormGroup name="audio" label="Title">
          {(formControl) => (
            <input
              {...formControl}
              name="title"
              type="text"
              className="form-control u-margin-top"
              placeholder="Title"
              value={this.data.title}
              onChange={(e) => this.updateData({ title: e.target.value })}
            />
          )}
        </FormGroup>
        <FormGroup name="audio" label="Audio File">
          {(formControl) => (
            <Fragment>
              {this.buildFileInput(0, {
                ...formControl,
                accept: ".mp3",
              })}
              <AudioPlayer
                trainingComponent={this.trainingComponent}
              />
            </Fragment>
          )}
        </FormGroup>
      </Fragment>
    );
  }
}

export default Audio;
