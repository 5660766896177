import React from "react";
import TrainingComponentEditor from "./TrainingComponentEditor";
import { observer } from "mobx-react";

@observer
class Review extends TrainingComponentEditor {
  initializeData() {
    return { text: "" };
  }

  render() {
    return null;
  }
}

export default Review;
