import ReactMarkdown from "react-markdown";
import React from "react";

const LinkRenderer = (props) => (
  <a href={props.href} target="_blank">
    {props.children}
  </a>
);

const Markdown = (props) => (
  <ReactMarkdown {...props} components={{ link: LinkRenderer }} />
);

export default Markdown;
