import React, { useState, useEffect } from "react";
import AudioPlayer from "./AudioPlayer";

const Audio = (props) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!props.training.id || !props.trainingComponent.id) {
      setError("Not enough info was provided to load the audio track.");
      return;
    }
  }, [props.training.id, props.trainingComponent.id]);

  return <AudioPlayer trainingComponent={props.trainingComponent} error={error} />;
};

export default Audio;
