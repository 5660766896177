import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import Markdown from "./Markdown";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import Practice from "./Practice";

@observer
class PracticeTchart extends Practice {
  @observable mode = "prompt";

  @action.bound
  onSubmit(e) {
    e.preventDefault();
    this.mode = "exemplar";
    const content = this.renderContent();
    this.updatePractice({
      content: content.outerHTML,
      el: this.table,
    });
  }

  renderContent() {
    const content = (
      <table
        className="table rl-small-table rl-tchart-table"
        style={{ borderCollapse: "collapse" }}
      >
        <thead>
          <tr>
            <th
              style={{
                padding: "10px",
                borderBottom: "1px solid #000",
                borderRight: "1px solid #000",
              }}
            >
              Teacher will...
            </th>
            <th style={{ padding: "10px", borderBottom: "1px solid #000" }}>
              Student will...
            </th>
          </tr>
        </thead>
        <tbody>
          {Array(4)
            .fill()
            .map((_, i) => {
              return (
                <tr key={`tchart-inputs-${i}`}>
                  <td
                    data-rel={`teacher-${i}`}
                    style={{ padding: "10px", borderRight: "1px solid #000" }}
                  >
                    {
                      this.table.querySelector(
                        `textarea[data-rel="teacher-${i}"]`
                      ).value
                    }
                  </td>
                  <td data-rel={`student-${i}`} style={{ padding: "10px" }}>
                    {
                      this.table.querySelector(
                        `textarea[data-rel="student-${i}"]`
                      ).value
                    }
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    );
    var container = document.createElement("div");
    return ReactDOM.render(content, container);
  }

  render() {
    const data = this.props.trainingComponent.data;
    const id = this.props.trainingComponent.id;

    return (
      <div className="row">
        <div className="col-md-6">
          {this.mode === "prompt" ? (
            <div className="t-chart-prompt">
              <Markdown>{data.prompts[0].text}</Markdown>
            </div>
          ) : (
            <div className="t-chart-exemplar">
              <div className="u-margin-bottom">
                <Markdown>{data.prompts[1].text}</Markdown>
              </div>
              <table className="table rl-small-table">
                <thead>
                  <tr>
                    <th>Teacher will...</th>
                    <th>Student will...</th>
                  </tr>
                </thead>
                <tbody>
                  {Array(4)
                    .fill()
                    .map((_, i) => {
                      return (
                        <tr key={`tchart-lines-${i}`}>
                          <td>
                            <Markdown>{data.lines[i].teacher}</Markdown>
                          </td>
                          <td>
                            <Markdown>{data.lines[i].student}</Markdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        <div className="col-md-6">
          <form
            action={`PracticeTchart-${id}`}
            className="rl-component-form rl-tchart-form"
            onSubmit={this.onSubmit}
          >
            <table
              ref={(e) => (this.table = e)}
              className="table rl-small-table rl-tchart-table"
            >
              <thead>
                <tr>
                  <th>Teacher will...</th>
                  <th>Student will...</th>
                </tr>
              </thead>
              <tbody>
                {Array(4)
                  .fill()
                  .map((_, i) => {
                    return (
                      <tr key={`tchart-inputs-${i}`}>
                        <td data-rel={`teacher-${i}`}>
                          <textarea
                            data-rel={`teacher-${i}`}
                            className="form-control"
                            aria-label="Enter a possible teacher action related to the lesson"
                            name={`col[${i}][]`}
                          />
                        </td>
                        <td data-rel={`student-${i}`}>
                          <textarea
                            data-rel={`student-${i}`}
                            className="form-control"
                            aria-label="Enter a possible student response to the teacher action"
                            name={`col[${i}][]`}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            <p className="text-right">
              {this.mode === "prompt" ? (
                <button className="button-form" type="submit">
                  Next
                </button>
              ) : null}
            </p>
          </form>
        </div>
      </div>
    );
  }
}

PracticeTchart.propTypes = {
  trainingComponent: PropTypes.object,
};

export default PracticeTchart;
