import xhrPost from "./xhrPost";
import * as JsRoutes from "./routes";

const trackProgress = ({ trainingId, section, practice }) => {
  const progress = section
    ? {
        section,
      }
    : {
        practice,
      };
  xhrPost(JsRoutes.track_progress_path(), {
    data: {
      progress,
      training_id: trainingId,
    },
  });
};

export default trackProgress;
