import React, { useEffect, useState, useRef } from "react";
import _ from "lodash";

const AudioPlayer = ({ trainingComponent }) => {
  const audioRef = useRef();
  const [progress, setProgress] = useState(0);
  const [playing, setPlaying] = useState(false);

  useEffect(() => {
    if (!audioRef || !audioRef.current) {
      return;
    }

    audioRef.current.addEventListener("timeupdate", (e) => {
      setProgress((100 * e.target.currentTime) / e.target.duration);
    });

    audioRef.current.addEventListener("ended", (e) => {
      setPlaying(false);
    });
  }, [audioRef]);

  if (!trainingComponent) return null;

  if ((_.isString(trainingComponent.id) && trainingComponent.id.includes("generated"))) return null;

  const playOrPause = () => {
    if (playing) {
      audioRef.current.pause();
      setPlaying(false);
    } else {
      audioRef.current.play();
      setPlaying(true);
    }
  };

  return (
    <div className="rl-audio audio-player">
      <div className="rl-audio-wrapper">
        <div className="rl-audio-progress" style={{ width: progress + "%" }} />
        <div className="rl-audio-bg" />
        <div className="rl-audio-title">{trainingComponent.data['title']}</div>
        {playing ? (
          <div className="rl-audio-pause" onClick={playOrPause}>
            <i className="fa fa-pause-circle fa-4x" />
          </div>
        ) : (
          <div className="rl-audio-play" onClick={playOrPause}>
            <i className="fa fa-play-circle fa-4x" />
          </div>
        )}
        <audio ref={audioRef} preload="auto">
          <source src={trainingComponent.trackUrl} />
        </audio>
      </div>
    </div>
  );
};

export default AudioPlayer;
