import React, { useState } from "react";
import { useForm } from "react-hook-form";

export const typeOptions = {
  publicSchool: "public-school",
  edPrepProgram: "ed-prep-program",
};

const AccountTypeForm = ({ value, onSubmit, onCancel, disabled }) => {
  const { handleSubmit } = useForm({
    defaultValues: {
      selectedOption: value?.selectedOption,
    },
  });
  const [selectedOption, setSelectedOption] = useState(value?.selectedOption);

  const submitType = () => {
    return onSubmit({
      selectedOption: selectedOption,
    });
  };

  return (
    <form onSubmit={handleSubmit(submitType)} className="w-full">
      <h3 className="text-lg leading-none my-6 font-bold">Account Type</h3>
      <div className="form-inputs">
        <div id="subscriptionPlanPicker">
          <div className="my-6 radio_boxes flex">
            <div className="radiobox mr-8">
              <label className="cursor-pointer">
                <input
                  type="radio"
                  id="acc-type-public-school"
                  name="group"
                  value="false"
                  checked={selectedOption === typeOptions.publicSchool}
                  onChange={() => setSelectedOption(typeOptions.publicSchool)}
                />
                I am employed at a Texas public School
              </label>
            </div>
            <div className="radiobox">
              <label className="cursor-pointer">
                <input
                  type="radio"
                  id="acc-type-ed-prep-program"
                  name="group"
                  value="true"
                  checked={selectedOption === typeOptions.edPrepProgram}
                  onChange={() => setSelectedOption(typeOptions.edPrepProgram)}
                />
                I am enrolled in a Texas Educator Preparation Program
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <button
          className="button-primary"
          type="submit"
          disabled={!selectedOption}
        >
          Next
        </button>
      </div>
    </form>
  );
};

export default AccountTypeForm;
