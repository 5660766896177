import React, { Component } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import guid from "../../../util/guid";

@observer
class VideoPlayer extends Component {
  parseUrl(url) {
    var parser = document.createElement("a");
    parser.href = url;
    return parser;
  }
  render() {
    const url = this.parseUrl(this.props.video.url);
    if (["vimeo.com", "player.vimeo.com"].includes(url.hostname)) {
      const vimeoId = url.pathname.split("/").pop();

      return (
        <div className="video-player video-player-vimeo">
          <iframe
            src={`https://player.vimeo.com/video/${vimeoId}?portrait=0&rel=0`}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            data-type="vimeo"
            id={`video-${guid()}`}
            width="100%"
          />
        </div>
      );
    } else if (["youtube.com", "www.youtube.com"].includes(url.hostname)) {
      let ytId = url.search.split("v=").pop();
      if (ytId.indexOf("&") != -1) {
        ytId = ytId.substring(0, ytId.indexOf("&"));
      }

      return (
        <div className="video-player video-player-youtube">
          <iframe
            src={`https://www.youtube.com/embed/${ytId}`}
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            id={`video-${guid()}`}
          />
        </div>
      );
    } else if (
      ["educator-evaluation-and-leadership.wistia.com", "wistia.com"].includes(
        url.hostname
      )
    ) {
      let identifier = url.pathname.split("/").at(-1);

      return (
        <div className="my-8">
          <script
            src="//fast.wistia.com/assets/external/E-v1.js"
            async
          ></script>
          <iframe
            src={`//fast.wistia.net/embed/iframe/${identifier}`}
            allowtransparency="true"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            allowFullScreen
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            oallowfullscreen="true"
            msallowfullscreen="true"
            width="592"
            height="360"
          ></iframe>
          {this.props.video.title ? (
            <div className="font-bold mt-2">{this.props.video.title}</div>
          ) : null}
        </div>
      );
    }
    return null;
  }
}

export default VideoPlayer;
