import React, { Component } from "react";
import PropTypes from "prop-types";
import { observable, action } from "mobx";
import { observer } from "mobx-react";

import VideoPlayer from "./VideoPlayer";

@observer
class VideoGallery extends Component {
  @observable activeVideo = null;
  @observable autoPlay = false;
  @observable videos = [];

  constructor(props) {
    super(props);

    const videos = this.props.trainingComponent.data.videos;
    if (videos) {
      this.videos = videos.filter((v) => v.url);
    }
  }

  render() {
    return (
      <div>
        {this.videos.map((video, idx) => (
          <div
            className="video-gallery-item"
            key={`vid-${this.props.trainingComponent.id}-${idx}`}
          >
            <VideoPlayer video={video} />
          </div>
        ))}
      </div>
    );
  }
}

VideoGallery.propTypes = {
  trainingComponent: PropTypes.object,
};

export default VideoGallery;
