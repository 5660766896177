import React from "react";
import { useForm } from "react-hook-form";
import FieldError from "../../FieldError";
import { PWD_FORMAT } from "../../../patterns";

const ProfileForm = ({ value, onSubmit, onCancel, disabled }) => {
  const { register, handleSubmit, errors, watch } = useForm({
    defaultValues: {
      first_name: value?.first_name,
      last_name: value?.last_name,
      password: value?.password,
      password_confirmation: value?.password_confirmation,
    },
  });

  const validatePasswordConfirmation = (value) => {
    return value !== watch("password") ? "must match password" : undefined;
  };

  const passwordRef = register({
    required: "is required",
    pattern: {
      value: PWD_FORMAT,
      message: "must mix letters (upper AND lower), digits and symbols",
    },
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="w-full">
      <h3 className="text-lg leading-none my-6 font-bold">Profile Details</h3>
      <div className="form-inputs white-bg-inputs">
        <div className="input">
          <label>First name</label>
          <input
            className="w-full"
            autoFocus="autofocus"
            autoComplete="first_name"
            type="text"
            name="first_name"
            ref={register({
              required: "is required",
              minLength: {
                value: 2,
                message: "should be longer than 1 character",
              },
            })}
          />
          <FieldError>{errors.first_name?.message}</FieldError>
        </div>
        <div className="input">
          <label>Last Name</label>
          <input
            className="w-full"
            autoComplete="last_name"
            type="text"
            name="last_name"
            ref={register({
              required: "is required",
              minLength: {
                value: 2,
                message: "should be longer than 1 character",
              },
            })}
          />
          <FieldError>{errors.last_name?.message}</FieldError>
        </div>

        <div className="row u-margin-bottom">
          <div className="input">
            <label>
              Choose Password
              <small>*</small>
            </label>
            <input
              className="w-full"
              autoComplete="new-password"
              type="password"
              name="password"
              aria-describedby="passwordHelpBlock"
              placeholder="Choose Password"
              ref={passwordRef}
            />
            <p className="mt-2 text-xxs">
              Your password must be at least 6 characters long and have a mix of
              upper and lower case letters, numbers and symbols.
            </p>
            <FieldError>{errors.password?.message}</FieldError>
          </div>
          <div className="input">
            <label>
              Confirm Password<small>*</small>
            </label>
            <input
              className="w-full"
              autoComplete="new-password "
              type="password"
              name="password_confirmation"
              placeholder="Confirm password"
              ref={register({
                required: "is required",
                validate: validatePasswordConfirmation,
              })}
            />
            <FieldError>{errors.password_confirmation?.message}</FieldError>
          </div>
        </div>
      </div>

      <div className="mt-4">
        <button className="button-form mr-4" onClick={onCancel}>
          Back
        </button>
        <button className="button-primary" type="submit" disabled={disabled}>
          Sign Up
        </button>
      </div>
    </form>
  );
};

export default ProfileForm;
