import guid from "../../util/guid";
import { computed, observable, toJS } from "mobx";
import * as routes from "../../util/routes";
import getMetaValue from "../../util/getMetaValue";
import camelizeKeys from "../../util/camelizeKeys";

class TrainingComponentModel {
  @observable id = `generated:${guid()}`;
  @observable type = null;
  @observable data = null;
  @observable section = "index";
  @observable attachments = [];
  files = [];
  hasScrolled = false;
  audioTypes = ['TrainingComponents::PracticeWriteAudioExemplar', 'TrainingComponents::Audio'];

  constructor(trainingComponent = null) {
    if (trainingComponent) {
      this.id = trainingComponent.id;
      this.type = trainingComponent.type;
      this.data = trainingComponent.data;
      this.section = trainingComponent.section;
      this.attachments.replace(trainingComponent.attachmentThumbnails);

      if(this.audioTypes.includes(this.type)){
        this.trackUrl = trainingComponent.trackUrl
      }
    }
  }

  addFile(idx, ref) {
    this.files.push([idx, ref]);
  }

  clearFiles() {
    this.files = [];
  }

  changeType(newType) {
    this.type = newType;
    this.data = null;
  }

  valid() {
    return this.type && this.data;
  }

  asJson() {
    const attachmentChanges = {
      purge: [],
      attach: [],
    };

    this.files.forEach(([attachmentIndex, ref]) => {
      if (!ref.current || ref.current.files.length == 0) {
        return;
      }

      const file = ref.current.files[0];
      const jsonFile = {
        name: `file__${this.id}__${attachmentIndex}`,
        filename: file.name,
        value: file,
        order: attachmentIndex + 1,
      };
      attachmentChanges.attach.push(jsonFile);

      const existingAttachment = this.attachments[attachmentIndex];
      existingAttachment && attachmentChanges.purge.push(existingAttachment);
    });

    const jsonTc = {
      type: this.type,
      data: this.data,
      attachments: attachmentChanges,
      attachmentsSaved: this.attachments.map((attachment) => attachment.url),
      section: this.section,
    };

    if (!isNaN(this.id)) {
      jsonTc.id = this.id;
    }

    return toJS(jsonTc);
  }

  asDestroyable() {
    return toJS({
      id: this.id,
      _destroy: true,
    });
  }

  @computed get isPersisted() {
    return !isNaN(this.id);
  }
}

export default class Store {
  @observable training = {
    id: 0,
    name: "",
    techniqueId: "",
    remoteModuleId: "",
    hook: "",
    order: 1,
    active: false,
    public: false,
    trainingComponents: [],
    sections: [{ id: "index", name: "Training" }],
  };

  @observable destroyableComponents = [];

  @observable techniques = [];

  @observable remoteModules = [];

  @computed get isPersisted() {
    return this.training.id != 0;
  }

  files = {};

  constructor({ training, techniques, remoteModules }) {
    if (training) this.updateTraining(training);
    this.techniques.replace(techniques);
    this.remoteModules.replace(remoteModules);
  }

  updateTraining(training) {
    training = camelizeKeys(training);
    this.training = {
      ...training,
      trainingComponents: training.trainingComponents
        .sort((tc1, tc2) => tc1.order - tc2.order)
        .map((tc) => new TrainingComponentModel(tc)),
    };
    this.destroyableComponents.clear();
  }

  addBlankComponent() {
    this.training.trainingComponents.push(new TrainingComponentModel());
  }

  removeComponent(value) {
    this.training.trainingComponents.remove(value);
    if (!isNaN(value.id)) this.destroyableComponents.push(value);
  }

  addFile(name, ref) {
    this.files[name] = ref;
  }

  clearFiles() {
    this.files = {};
  }

  asJson() {
    const jsonComponents = this.training.trainingComponents
      .filter((tc) => tc.valid())
      .map((tc, idx) => {
        const tcJson = tc.asJson();
        tcJson.order = idx + 1;
        return tcJson;
      });

    const jsonTraining = {
      technique_id: this.training.techniqueId,
      remote_module_id: this.training.remoteModuleId,
      name: this.training.name,
      hook: this.training.hook,
      order: this.training.order,
      active: this.training.active,
      public: this.training.public,
      thumbnail: this.training.thumbnail || undefined,
      sections: this.training.sections,
      training_components_attributes: jsonComponents.concat(
        this.destroyableComponents.map((tc) => tc.asDestroyable())
      ),
    };

    const topLevelFiles = Object.entries(this.files)
      .filter(([_, ref]) => ref.current && ref.current.files.length > 0)
      .map(([name, ref]) => {
        const file = ref.current.files[0];
        const fileId = `file__${name}__0`;
        jsonTraining[name] = fileId;

        return {
          name: fileId,
          filename: file.name,
          value: file,
          order: 1,
        };
      });

    const jsonFiles = jsonComponents
      .map((tc) => tc.attachments.attach)
      .concat(topLevelFiles)
      .flat();

    return toJS({
      json: {
        training: jsonTraining,
      },
      files: jsonFiles,
    });
  }

  delete() {
    return fetch(routes.admin_training_path(this.training.id), {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
    });
  }

  save() {
    const action = this.isPersisted
      ? routes.admin_training_path(this.training.id)
      : routes.admin_trainings_path();

    const formData = new FormData();
    const formJson = this.asJson();
    const trainingJson = JSON.stringify(formJson.json);
    formData.append(
      "training",
      new Blob([trainingJson], {
        type: "application/json",
      }),
      "training.json"
    );

    formJson.files.forEach((file) => {
      formData.append(file.name, file.value, file.filename);
    });

    return fetch(action, {
      method: this.isPersisted ? "PATCH" : "POST",
      body: formData,
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
    }).then((response) => {
      if (response.ok) {
        return response.json().then((training) => {
          this.updateTraining(training);
          this.clearFiles();
          return training;
        });
      } else {
        throw response;
      }
    });
  }
}
