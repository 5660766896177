import React from "react";
import { observer } from "mobx-react";
import { action } from "mobx";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";

@observer
class Chapter extends TrainingComponentEditor {
  initializeData() {
    return {
      text: "",
    };
  }

  @action.bound
  handleTextChange(event) {
    this.data.text = event.target.value;
  }

  render() {
    return (
      <FormGroup name="text" label="Chapter">
        {(formControl) => (
          <textarea
            {...formControl}
            onChange={this.handleTextChange}
            value={this.data.text}
          />
        )}
      </FormGroup>
    );
  }
}

export default Chapter;
