import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import Practice from "./Practice";
import AudioPlayer from "./AudioPlayer";

@observer
class PracticeWriteAudioExemplar extends Practice {
  @observable mode = "prompt";
  @observable trackInfo = null;
  @observable trackError = null;

  @action.bound
  componentDidMount() {
    if (!this.props.training.id || !this.props.trainingComponent.id) {
      this.trackError =
        "Could not load audio track right now. Please try again later.";
      return;
    }
  }

  @action.bound
  onSubmit(e) {
    e.preventDefault();
    this.mode = "exemplar";
    this.updatePractice({
      content: this.input.value,
      el: this.input,
    });
  }

  render() {
    const data = this.props.trainingComponent.data;
    const id = this.props.trainingComponent.id;
    return (
      <div className="row">
        <div className="col-md-5">
          <Markdown>
            {this.mode == "prompt" ? data.prompt : data.exemplar}
          </Markdown>
        </div>
        <div className="col-md-7">
          {this.mode === "exemplar" ? (
            <div className="u-margin-bottom">
              <AudioPlayer
                trainingComponent={this.props.trainingComponent}
                error={this.trackError}
              />
            </div>
          ) : null}
          <form
            action={`#PracticeWriteAudioExemplar-${id}`}
            className="rl-component-form"
            onSubmit={this.onSubmit}
          >
            <textarea
              ref={(e) => (this.input = e)}
              className="form-control u-margin-bottom"
              aria-label={this.mode == "prompt" ? data.prompt : data.exemplar}
              style={{ height: "200px" }}
              defaultValue={this.defaultValue}
            />

            <p className="text-right">
              {this.mode === "prompt" ? (
                <button type="submit" className="button-form">
                  Next
                </button>
              ) : null}
            </p>
          </form>
        </div>
      </div>
    );
  }
}

PracticeWriteAudioExemplar.propTypes = {
  trainingComponent: PropTypes.object,
};

export default PracticeWriteAudioExemplar;
