import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import practices from "../../../stores/practices";
import Share from "./Share";
import gaTrackEvent from "../../../gaTrackEvent";
import * as JsRoutes from "../../../util/routes";
import xhrPost from "../../../util/xhrPost";
import TrainingCompleteSuccessMessage from "./TrainingCompleteSuccessMessage";

const TOOLTIPS = {
  video: "Complete all the video recordings to enable sharing.",
  planning: "Complete the practices to enable this feature.",
  videoComplete: "Well done. You’re ready to share! Click the button below.",
  planningComplete: "Well done. You’re ready to share! Click the button below.",
};
const LABELS = {
  video: "Share Final Practice",
  planning: "Email",
};

@observer
class Closing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      working: false,
      sharing: false,
      completed: false,
    };
  }

  handleRequestClose = () => {
    this.setState({ sharing: false, completed: false });
  };

  handleShare = () => {
    this.setState({ sharing: true });
  };

  handleMarkAsComplete = (e) => {
    e.preventDefault();

    if (this.state.working || !practices.allDone()) {
      return;
    }

    let { trainingComponent } = this.props;
    const closingType = trainingComponent.data.closingType;

    let result = {
      type: closingType,
    };

    this.setState({ working: true });

    gaTrackEvent(
      "Module",
      "complete",
      `${trainingComponent.id} - ${trainingComponent.name}`
    );

    const lastPractice = practices.last();
    if (closingType === "video") {
      result.video_id = lastPractice.camera_tag_id;
    } else if (closingType === "planning") {
      result.content = lastPractice.content;
    }

    xhrPost(JsRoutes.save_training_path(trainingComponent.trainingId), {
      data: {
        result,
      },
      success: () => {
        this.setState({
          working: false,
          completed: true,
        });
      },
      error: (e) => {
        this.setState({ working: false });
        setError(
          `There was a problem when saving your practice. Please try again later.\n${e}`
        );
      },
    });
  };

  render() {
    const component = this.props.trainingComponent;
    const closingType = component.data.closingType;
    const active = practices.allDone();
    const shareButton = this.props.config.user ? (
      <button
        ref={(e) => (this.shareBtn = e)}
        className="button-primary rl-closing"
        disabled={!active}
        title={TOOLTIPS[closingType]}
        onClick={this.handleShare}
      >
        {component.data.buttonLabel || LABELS[closingType]}
      </button>
    ) : (
      <button className="button-primary rl-closing auth-control-signup">
        Share
      </button>
    );
    return (
      <Fragment>
        <div className="mt-2 text-center">
          {active ? (
            <p className="text-sm font-bold my-2">
              {TOOLTIPS[`${closingType}Complete`]}
            </p>
          ) : (
            <p className="text-sm font-bold my-2">{TOOLTIPS[closingType]}</p>
          )}
          {shareButton}
        </div>
        <Share
          isOpen={this.state.sharing}
          onRequestClose={this.handleRequestClose}
          user={this.props.config.user}
          closingType={closingType}
          training={this.props.training}
          trainingComponent={component}
        />
        <TrainingCompleteSuccessMessage
          isOpen={this.state.completed}
          onRequestClose={this.handleRequestClose}
        />
        <div className="mt-5 text-center">
          {active ? (
            <p className="text-sm my-2">
              If you're not ready to share, you can{" "}
              <span className="underline font-bold cursor-pointer">
                <a onClick={this.handleMarkAsComplete}>
                  mark the module as complete.
                </a>
              </span>
            </p>
          ) : (
            <p className="text-sm my-2">
              Once you have completed this module, you will be able to mark it
              as completed even if you are not ready to share.
            </p>
          )}
        </div>
      </Fragment>
    );
  }
}

Closing.propTypes = {
  trainingComponent: PropTypes.object,
  config: PropTypes.object,
};

export default Closing;
