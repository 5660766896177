import React from "react";
import Modal from "react-modal";

const TlacModal = (props) => {
  const handleAfterOpen = () => {
    document.body.style.overflow = "hidden";
    document.querySelector(".ReactModal__Overlay").scrollTop = 0;
  };

  const handleAfterClose = () => {
    document.body.style.overflow = "initial";
  };

  const propsWithoutChildren = { ...props };
  delete propsWithoutChildren.children;

  return (
    <Modal
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      className="ReactModal__Content"
      overlayClassName="ReactModal__Overlay"
      ariaHideApp={false}
      {...propsWithoutChildren}
    >
      <div className="tlac-modal-body">
        <div className="text-right text-4xl mr-4 text-primary-grey-blue">
          <button onClick={props.onRequestClose}>&times;</button>
        </div>
        {props.children}
      </div>
    </Modal>
  );
};

export default TlacModal;
