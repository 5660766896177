import React, { useCallback, useMemo, useRef, useState } from "react";
import BulkUploadTeaser from "./BulkUploadTeaser";
import { debounce } from "throttle-debounce";
import gaTrackEvent from "../../gaTrackEvent";
import extractEmails from "../../util/extractEmails";
import { v4 as uuidv4 } from 'uuid';
import UserCount from "./UserCount";

const GA_EVENT_LABEL = "addteachers";

const EmailInput = ({ onChange, onError, teamName }) => {
  const idRef = useRef(uuidv4());
  const [error, setError] = useState(null);

  const handleChange = useCallback(
    debounce(1000, (inputEmails) => {
      if (!inputEmails) {
        setError(null);
        onChange([]);
        return;
      }

      const emailExtraction = extractEmails(inputEmails);

      if (emailExtraction.errors.length) {
        setError(
          `Please check that the following addresses are correct: ${emailExtraction.errors.join(
            ", "
          )}`
        );

        onError();
        return;
      }

      setError(null);
      onChange(emailExtraction.emails);
    }),
    [onChange]
  );

  return (
    <>
      <label className="block font-bold mb-2" htmlFor={idRef.current}>
        Add teachers{teamName ? ` to: ${teamName}` : ""}
      </label>
      <div className="input">
        <textarea
          className="w-full"
          id={idRef.current}
          onChange={(e) => handleChange(e.target.value)}
          placeholder="Add email addresses"
        />
        {error ? <p className="error-message">{error}</p> : null}
      </div>
    </>
  );
};

const AddTeachers = (props) => {
  const teams = props.teams.length ? props.teams : [{}];
  const emailsRef = useRef({});
  const [invalidTeamIds, setInvalidTeamIds] = useState(new Set());
  const [teachers, setTeachers] = useState({});

  const submit = () => gaTrackEvent("manager", "addteachers", GA_EVENT_LABEL);

  const skip = () => {
    setTeachers({});
    gaTrackEvent("manager", "skip", GA_EVENT_LABEL);
  };

  const handleChange = (teamId, emails) => {
    setInvalidTeamIds(s => {
      s.delete(teamId);
      return new Set(s);
    });

    updateTeachers(teamId, emails);
  };

  const handleError = (teamId) => {
    setInvalidTeamIds(s => new Set(s.add(teamId)));
    updateTeachers(teamId, []);
  };

  const updateTeachers = (teamId, emails) => {
    emailsRef.current = {
      ...emailsRef.current,
      [teamId]: emails
    };

    const teachers = {};

    for (const team of teams) {
      const { id } = team;
      const emails = emailsRef.current[id] || [];

      for (const email of emails) {
        if (teachers[email]) {
          teachers[email].push(id);
        } else {
          teachers[email] = [id];
        }
      }
    }

    setTeachers(teachers);
  };

  const remainingUsers = useMemo(
    () => props.usersLeft - Object.keys(teachers).length,
    [teachers]
  );

  const disabled = useMemo(
    () => invalidTeamIds.size || !Object.keys(teachers).length || remainingUsers < 0,
    [invalidTeamIds, teachers]
  );

  return (
    <>
      <div className="form-inputs white-bg-inputs mt-6">
        {teams.map((team) => (
          <EmailInput
            key={team.id}
            onChange={handleChange.bind(null, team.id)}
            onError={handleError.bind(null, team.id)}
            teamName={team.name}
          />
        ))}
        <p>
          <UserCount remaining={remainingUsers} total={props.usersLeft} />
        </p>
        {remainingUsers < 0 && (
          <p className="error-message">
            Your group has reached its maximum number of users.
          </p>
        )}
        <div className="hidden">
          {Object.entries(teachers).map(([email, teamIds]) => (
            <input
              key={email}
              name={`teachers[${email}]`}
              type="hidden"
              value={teamIds.join(",")}
            />
          ))}
        </div>
        <div className="flex mt-6">
          <button
            className="button-form"
            type="submit"
            disabled={disabled}
            onClick={submit}
          >
            Add Teachers
          </button>
          <button className="button-link" type="submit" onClick={skip}>
            I'll add more teachers later
          </button>
        </div>
      </div>
      <div className="mt-6">
        <BulkUploadTeaser gaEventLabel={GA_EVENT_LABEL} />
    	</div>
    </>
  );
};

export default AddTeachers;
