import React, { Fragment, Component } from "react";
import PropTypes from "prop-types";
import { action } from "mobx";
import { observer } from "mobx-react";
import TrainingComponentEditor, { FormGroup } from "./TrainingComponentEditor";
import VideoPlayer from "./VideoPlayer";
import guid from "../../../util/guid";

@observer
class VideoItem extends Component {
  htmlId = guid();

  @action.bound
  onChangeUrl(event) {
    this.props.video.url = event.target.value;
  }

  @action.bound
  onChangeTitle(event) {
    this.props.video.title = event.target.value;
  }

  render() {
    return (
      <Fragment>
        <FormGroup name="title" label="Video Title">
          {(formControl) => (
            <input
              {...formControl}
              value={this.props.video.title || ""}
              onChange={this.onChangeTitle}
            />
          )}
        </FormGroup>
        <FormGroup name="url" label="Video URL">
          {(formControl) => (
            <Fragment>
              <input
                {...formControl}
                type="text"
                value={this.props.video.url}
                placeholder="Video URL"
                onChange={this.onChangeUrl}
              />
              <div className="mt-2">
                <VideoPlayer video={this.props.video} />
              </div>
            </Fragment>
          )}
        </FormGroup>
      </Fragment>
    );
  }
}

VideoItem.propTypes = PropTypes.shape({
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
}).isRequired;

@observer
class Video extends TrainingComponentEditor {
  initializeData(numVideos = 1, proportion = 100) {
    const newData = {
      proportion: proportion,
      numVideos: numVideos,
      videos: [],
    };
    for (let i = 0; i < numVideos; i++) {
      newData.videos.push({ url: "", title: "" });
    }
    return newData;
  }

  @action.bound
  onChangeNumVideo(evt) {
    const numVideos = parseInt(evt.target.value);

    if (numVideos == 2) {
      this.updateData(this.initializeData(numVideos, 50));
    } else {
      this.updateData(this.initializeData(numVideos));
    }
  }

  @action.bound
  onChangeProportion(evt) {
    this.data.proportion = parseInt(evt.target.value);
  }

  render() {
    return (
      <Fragment>
        <FormGroup name="num-videos" label="Number of videos">
          {(formControl) => (
            <select
              {...formControl}
              value={this.data.numVideos}
              onChange={this.onChangeNumVideo}
            >
              <option value="1">1</option>
              <option value="2">2</option>
            </select>
          )}
        </FormGroup>
        <FormGroup name="proportion" label="Proportion">
          {(formControl) =>
            this.data.numVideos == 2 ? (
              <select {...formControl} value={this.data.proportion} disabled>
                <option value="33">Two at 50%</option>
              </select>
            ) : (
              <select
                {...formControl}
                value={this.data.proportion}
                onChange={this.onChangeProportion}
              >
                <option value="100">One at 100%</option>
                <option value="65">One at 65%</option>
              </select>
            )
          }
        </FormGroup>
        {this.data.videos.map((video, idx) => (
          <VideoItem
            key={`${this.props.trainingComponent.id}-videoitem-${idx}`}
            video={video}
          />
        ))}
      </Fragment>
    );
  }
}

export default Video;
