import React from "react";
import PropTypes from "prop-types";
import Markdown from "./Markdown";

const Text = ({ trainingComponent }) => (
  <Markdown>{trainingComponent.data.text}</Markdown>
);

Text.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Text;
