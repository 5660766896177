import React, { useCallback, useEffect, useState } from "react";
import BulkUploadTeaser from "./BulkUploadTeaser";
import replaceArrayItem from "../../util/replaceArrayItem";
import { debounce } from "throttle-debounce";
import gaTrackEvent from "../../gaTrackEvent";
import UserCount from "./UserCount";

const GA_EVENT_LABEL = "addcoaches";

const AddCoaches = (props) => {
  const [teamCoaches, setTeamCoaches] = useState([]);

  useEffect(() => {
    const team = props.teams[0];

    if (!team) {
      return;
    }

    setTeamCoaches([{ coachEmail: "", team }]);
  }, [props.teams]);

  const updateCoachEmail = useCallback(
    debounce(1000, (currentTeamCoaches, teamCoachIdx, newEmail) => {
      newEmail = newEmail.replace(/\s/g, "");
      let error = null;
      if (newEmail) {
        if (!/\S+@\S+\.\S+/.test(newEmail)) {
          error = `${newEmail} doesn't look like a valid e-mail address.`;
        } else if (remainingUsers == 0) {
          error = "You no longer have any users available in your group.";
        }
      }

      setTeamCoaches(
        replaceArrayItem(currentTeamCoaches, teamCoachIdx, (teamCoach) => ({
          coachEmail: newEmail,
          team: teamCoach.team,
          error,
        }))
      );
    }),
    []
  );

  const validTeamCoaches = teamCoaches.filter(
    (tc) => tc.coachEmail && !tc.error
  );

  const remainingUsers = props.usersLeft - validTeamCoaches.length;

  const onChangeCoachEmail = (e) => {
    const teamCoachIdx = parseInt(e.target.getAttribute("data-team-coach-idx"));
    updateCoachEmail(teamCoaches, teamCoachIdx, e.target.value);
  };

  const onChangeCoachTeam = (e) => {
    const teamCoachIdx = parseInt(e.target.getAttribute("data-team-coach-idx"));
    setTeamCoaches(
      replaceArrayItem(teamCoaches, teamCoachIdx, (teamCoach) => ({
        coachEmail: teamCoach.coachEmail,
        team: props.teams.find((t) => t.id == parseInt(e.target.value)),
      }))
    );
  };

  const addCoach = (e) => {
    e.preventDefault();

    if (remainingUsers == 0) {
      return;
    }

    setTeamCoaches([...teamCoaches, { coachEmail: "", team: props.teams[0] }]);
  };

  const submit = () => gaTrackEvent("manager", "invite", GA_EVENT_LABEL);

  const skip = (e) => {
    setTeamCoaches([]);
    gaTrackEvent("manager", "skip", GA_EVENT_LABEL);
  };

  return (
    <>
      <div className="form-inputs white-bg-inputs mt-6">
        {props.teams.length ? (
          <div>
            <p className="my-4">Invite coaches to manage your group's teams</p>
            {teamCoaches.map((teamCoach, idx) => (
              <div key={`tc-${idx}`}>
                <div className="flex">
                  <div className="input w-3/4 mr-4">
                    <input
                      data-team-coach-idx={idx}
                      type="text"
                      className="w-full"
                      placeholder="Coach's e-mail address"
                      onChange={onChangeCoachEmail}
                    />
                  </div>
                  <div className="input relative w-1/4">
                    <select
                      data-team-coach-idx={idx}
                      value={teamCoach.team.id}
                      className="appearance-none bg-white block border border-gray-400 focus:outline-none focus:ring leading-tight px-4 py-2 pr-8 rounded w-full"
                      onChange={onChangeCoachTeam}
                    >
                      {props.teams.map((team) => (
                        <option key={`team-${idx}-${team.id}`} value={team.id}>
                          {team.name}
                        </option>
                      ))}
                    </select>
                    <div
                      className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg
                        className="fill-current h-4 w-4"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                      </svg>
                    </div>
                  </div>
                </div>

                {teamCoach.error ? (
                  <div className="error-message mb-4">{teamCoach.error}</div>
                ) : null}
              </div>
            ))}
            <button className="button-link pl-0" onClick={addCoach} type="button">
              Add another coach
            </button>
            <p className="mt-4">
              <UserCount remaining={remainingUsers} total={props.usersLeft} />
            </p>
            {validTeamCoaches.length ? (
              <div className="hidden">
                {validTeamCoaches.map((tc, idx) => (
                  <div key={`tc-hidden-${idx}`}>
                    <input
                      type="hidden"
                      name="team_coaches[][email]"
                      value={tc.coachEmail}
                    />
                    <input
                      type="hidden"
                      name="team_coaches[][team_id]"
                      value={tc.team.id}
                    />
                  </div>
                ))}
              </div>
            ) : null}
          </div>
        ) : (
          <p>
            Looks you haven't added any teams yet. If you changed your mind, you
            can add teams and coaches later.
          </p>
        )}

        <div className="flex mt-6">
          <button
            className="button-form"
            type="submit"
            disabled={validTeamCoaches.length == 0}
            onClick={submit}
          >
            Invite Coaches
          </button>
          <button className="button-link" onClick={skip} type="submit">
            Skip this, I'll invite coaches later
          </button>
        </div>
      </div>
      <div className="mt-6">
        <BulkUploadTeaser gaEventLabel={GA_EVENT_LABEL} />
    	</div>
    </>
  );
};

export default AddCoaches;
