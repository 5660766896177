const gaTrackEvent = (category, action, label, numValue) => {
  if (!window.gtag) {
    window.dataLayer = window.dataLayer || [];
    window.gtag = () => {
      dataLayer.push(arguments);
    };
  }
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value: numValue,
  });
};

export default gaTrackEvent;
