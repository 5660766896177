import React, { Component } from "react";
import PropTypes from "prop-types";
import { action } from "mobx";
import { observer } from "mobx-react";
import practices from "../../../stores/practices";
import trackVideo from "../../../util/trackVideo";
import trackProgress from "../../../util/trackProgress";

@observer
class Practice extends Component {
  practiceType = "planning"; // default

  constructor(props) {
    super(props);
    const existingPractice = this.findExistingPractice();
    if (existingPractice) {
      this.defaultValue =
        existingPractice.content?.input || existingPractice.content;
    } else {
      this.defaultValue = "";
    }
  }

  get practiceId() {
    return this.props.trainingComponent.id;
  }

  findExistingPractice() {
    if (this.props?.trainingProgress?.details?.practices) {
      const existingPractice = this.props.trainingProgress.details.practices.find(
        (p) => p.training_component_id == this.props.trainingComponent.id
      );
      if (existingPractice) {
        return existingPractice;
      }
    }

    return null;
  }

  componentDidMount() {
    this.addPractice();
  }

  addPractice() {
    practices.add(this.practiceId, {
      active: this.defaultValue ? true : false,
      content: this.defaultValue ? this.defaultValue : undefined,
      practiceType: this.practiceType,
    });
  }

  @action.bound
  updatePractice(attrs) {
    let updateAttrs;
    let active = true;

    if (this.practiceType == "planning") {
      updateAttrs = attrs;
      if (attrs.content) {
        trackProgress({
          trainingId: this.props.training.id,
          practice: {
            training_component_id: this.props.trainingComponent.id,
            content: attrs.content,
          },
        });
      } else {
        active = false;
      }
    } else {
      updateAttrs = {
        camera_tag_id: attrs.id,
        camera_tag_uuid: attrs.video.uuid,
        url: attrs.src,
        medias: attrs.video.medias,
        meta: attrs.metadata,
      };
      active = true;
      if (this.input?.value) {
        updateAttrs.input = this.input.value;
      }
      trackProgress({
        trainingId: this.props.training.id,
        practice: {
          training_component_id: this.props.trainingComponent.id,
          content: updateAttrs,
        },
      });
      trackVideo({ ...updateAttrs, training_id: this.props.training.id });
    }

    practices.update(this.practiceId, { ...updateAttrs, active });
  }
}

Practice.propTypes = {
  trainingComponent: PropTypes.object,
};

export default Practice;
