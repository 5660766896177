import React, { Component } from "react";
import TrainingComponent from "../TrainingComponent";
import { observer } from "mobx-react";
import { action } from "mobx";

@observer
class TrainingSection extends Component {
  constructor(props) {
    super(props);
  }

  @action.bound
  handleNext(e) {
    e.preventDefault();
    this.props.onNext(this.props.sectionIndex, this.props.section);
  }

  render() {
    const idx = this.props.sectionIndex + 1;
    return (
      <div
        id={`ts-${this.props.section.id}`}
        className="container py-12 mx-auto"
      >
        <div className="training-component-container">
          <h3 className="mb-4 font-bold text-xl">
            {idx}. {this.props.section.name}
          </h3>
        </div>
        {this.props.trainingComponents.map((tc) => (
          <TrainingComponent
            training={this.props.training}
            trainingComponent={tc}
            trainingProgress={this.props.trainingProgress}
            section={this.props.section}
            key={`tc-${tc.id}`}
            config={this.props.config}
            previewMode={this.props.previewMode}
          />
        ))}
        {this.props.hasNext ? (
          <div className="text-center mt-12">
            <button onClick={this.handleNext} className="button-primary">
              Next
            </button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default TrainingSection;
