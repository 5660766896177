import React, { useState } from "react";
import Modal from "./Modal";
import Login from "./Login";
import SignUpSubscription from "./signup/tlaconline/SignUpSubscription";
import TexasSignUp from "./signup/texas/TexasSignUp";

const AuthComponent =
  window.tlac_instance == "texas" ? TexasSignUp : SignUpSubscription;
import gaTrackEvent from "../gaTrackEvent";

const AuthenticationModal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);
  const [authType, setAuthType] = useState(props.authType || "login");

  const loginHandler = (e) => {
    e.preventDefault();
    setAuthType("login");
    setIsOpen(true);
  };

  const signUpHandler = (e) => {
    e.preventDefault();
    setAuthType("signup");
    setIsOpen(true);
  };

  document.addEventListener("click", (e) => {
    const target = e.target;
    if (target.classList.contains("auth-control-login")) {
      loginHandler(e);
    } else if (target.classList.contains("auth-control-signup")) {
      if (target.classList.contains("auth-control-header")) {
        gaTrackEvent("sign-up", "header-button", "click");
      } else if (target.classList.contains("auth-control-body")) {
        gaTrackEvent("sign-up", "body-button", "click");
      }
      signUpHandler(e);
    }
  });

  const closeModal = () => setIsOpen(false);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      {authType === "login" ? (
        <Login onChangeAuthType={setAuthType} />
      ) : (
        <AuthComponent
          subscriptionPlans={props.subscriptionPlans}
          edPrepPrograms={props.edPrepPrograms}
          onChangeAuthType={setAuthType}
        />
      )}
    </Modal>
  );
};

export default AuthenticationModal;
