import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { observer } from "mobx-react";
import TrainingComponents from "./TrainingComponents";
import PreviewTrainingComponents from "../TrainingComponents";
import * as routes from "../../util/routes";
import { action, observable, computed } from "mobx";
import Thumbnail from "./Thumbnail";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import guid from "../../util/guid";
import Modal from "../Modal";
import Store from "../../stores/admin/edit_training_store";
import getMetaValue from "../../util/getMetaValue";

const STATUS_COLORS = {
  working: "#F1C40F",
  success: "#27AE60",
  failure: "#E74C3C",
};

const formFieldFor = (errors) => {
  const Component = ({ name, children }) => {
    const errorMessages = errors.get()[name]
      ? errors.get()[name].map((error, idx) => (
          <div key={`error-${idx}`} className="error-message">
            <span className="error-field-name">{name}:</span>&nbsp;
            <span className="error-field-message">{error}</span>
          </div>
        ))
      : null;

    return (
      <div className="input">
        {children}
        <div className="error-messages">{errorMessages}</div>
      </div>
    );
  };

  Component.propTypes = {
    name: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]).isRequired,
  };

  return observer(Component);
};

const DragHandle = SortableHandle(() => (
  <span className="button-form cursor-grab">
    <i className="fa fa-bars mr-1"></i>
    Reorder
  </span>
));

const TSItem = SortableElement(
  observer(({ section, order, onDelete, enableReorder }) => {
    const handleChange = (e) => {
      section.name = e.target.value;
    };
    const handleDelete = (e) => {
      e.preventDefault();
      const msg =
        "Deleting a section will move all of the section's components to the first section. Proceed?";
      if (confirm(msg)) {
        onDelete(section);
      }
    };
    return (
      <li className="ts-section-item form-inputs">
        <div className="input inline-block">
          <input type="text" value={section.name} onChange={handleChange} />
        </div>
        <div className="ml-4 inline-block">
          {enableReorder ? <DragHandle /> : null}
          {order > 0 ? (
            <button className="button-danger ml-4" onClick={handleDelete}>
              Delete
            </button>
          ) : null}
        </div>
      </li>
    );
  })
);

const TSList = SortableContainer(
  observer(({ sections, onDelete }) => {
    const handleAddSection = (e) => {
      e.preventDefault();
      sections.push({
        id: guid(),
        name: `Training ${sections.length + 1}`,
      });
    };

    const handleDeleteSection = (section) => {
      sections.remove(section);
      onDelete(section);
    };

    return (
      <div>
        <ol className="training-form-sections">
          {sections.map((section, idx) => (
            <TSItem
              key={`edit-section-${section.id}`}
              section={section}
              index={idx}
              order={idx}
              onDelete={handleDeleteSection}
              enableReorder={sections.length > 1}
            />
          ))}
        </ol>
        <button className="button-form" onClick={handleAddSection}>
          Add section
        </button>
      </div>
    );
  })
);

const TrainingSections = observer(({ sections, onDelete }) => {
  const onSortEnd = ({ oldIndex, newIndex }) => {
    sections.replace(arrayMove(sections.slice(), oldIndex, newIndex));
  };

  return (
    <TSList
      sections={sections}
      onDelete={onDelete}
      onSortEnd={onSortEnd}
      useDragHandle={true}
      helperClass="dragging"
    />
  );
});

TrainingComponents.propTypes = {
  store: PropTypes.object.isRequired,
};

@observer
class EditTraining extends Component {
  @observable errors = observable.box({});

  @observable statusText = null;

  @observable statusColor = null;

  @observable loading = false;

  @observable previewProps = {
    trainingComponents: [],
    training: { sections: [] },
  };

  @observable store;

  @observable preview = false;

  statusTimeout = null;

  @computed get training() {
    return this.store.training;
  }

  constructor(props) {
    super(props);
    this.store = new Store({
      training: props.training,
      techniques: props.techniques,
      remoteModules: props.remoteModules,
    });
    this.FormField = formFieldFor(this.errors);
    this.thumbnailRef = React.createRef();
    this.store.addFile("thumbnail", this.thumbnailRef);
    this.preview = props.preview ? true : false;
  }

  componentDidMount() {
    if (this.preview) {
      this.handlePreview();
    }
  }

  @action.bound
  handleChange(property, value) {
    this.training[property] = value;

    if (property == "techniqueId" && value) {
      fetch(
        routes.related_remote_modules_admin_trainings_path({
          technique_id: value,
        }),
        {
          headers: {
            Accept: "application/json",
            "X-CSRF-Token": getMetaValue("csrf-token"),
          },
        }
      )
        .then((r) => r.json())
        .then((newRemoteModules) => {
          this.store.remoteModules.replace(newRemoteModules);
          this.training.remoteModuleId = "";
        });
    }
  }

  @action.bound
  handleSave(event) {
    event.preventDefault();

    const isNew = !this.store.isPersisted;

    this.setStatus("Working...", STATUS_COLORS.working, true);

    this.store
      .save()
      .then((training) => {
        this.setStatus("Saved successfully.", STATUS_COLORS.success);

        if (isNew) {
          window.location.href = routes.edit_admin_training_path(training.id);
        } else {
          this.store.addFile("thumbnail", this.thumbnailRef);
          this.errors.set({});
        }
      })
      .catch((response) => {
        this.setStatus(
          "There was a problem when saving.",
          STATUS_COLORS.failure
        );

        if (response.status >= 400 && response.status < 500) {
          response.json().then((responseErrors) => {
            this.errors.set(responseErrors);
          });
        }
      });
  }

  @action.bound
  handleDelete(event) {
    event.preventDefault();

    const shouldDelete = confirm(
      "Please confirm the deletion of this training."
    );

    if (!shouldDelete) {
      return;
    }

    this.store.delete().then(() => {
      window.location.href = routes.admin_trainings_path();
    });
  }

  @action.bound
  handlePreview(event) {
    if (event) {
      event.preventDefault();
    }

    const { json } = this.store.asJson();
    const training = json.training;
    const trainingComponents = training.training_components_attributes;

    trainingComponents.forEach((tc) => {
      if (!tc.id) {
        const randomId = parseInt(Math.random() * 1000000000, 10);
        tc.id = randomId;
      }

      const atts = tc.attachments;
      if (atts && atts.attach && atts.attach.length > 0) {
        tc.attachments = atts.attach.map((a) => {
          return URL.createObjectURL(a.value);
        });
      }
    });

    this.previewProps = {
      training: {
        id: training.id,
        name: training.name,
        hook: training.hook,
        order: training.order,
        sections: training.sections,
      },
      trainingComponents: trainingComponents,
      config: this.props.config,
    };

    this.preview = true;
  }

  @action.bound
  handleDeleteSection(section) {
    const firstSection = this.training.sections[0];
    this.store.training.trainingComponents.forEach((tc) => {
      if (tc.section !== section.id) {
        return;
      }
      tc.section = firstSection.id;
    });
  }

  setStatus(text, color, loading = false) {
    this.statusColor = color;
    this.statusText = text;
    this.loading = loading;

    clearTimeout(this.statusTimeout);
    this.statusTimeout = window.setTimeout(() => {
      this.statusText = null;
    }, 8000);
  }

  @action.bound
  closePreview() {
    this.preview = false;
  }

  previewModal() {
    return (
      <Modal isOpen={this.preview} onRequestClose={this.closePreview}>
        <div className="text-primary-grey-blue px-8 pt-4 pb-8">
          <h1>{this.previewProps.training.name} (Preview)</h1>
          <p>{this.previewProps.training.hook}</p>
        </div>
        <div className="bg-white mx-8 px-8 pb-4 text-primary-grey-blue">
          <PreviewTrainingComponents
            {...this.previewProps}
            previewMode={true}
          />
        </div>
        <div className="h-10" />
      </Modal>
    );
  }

  render() {
    return (
      <Fragment>
        <form className="training-form">
          <div className="flex">
            <div className="w-3/5">
              <div className="form-inputs input-full">
                <this.FormField name="name">
                  <label htmlFor="name">Name</label>
                  <input
                    id="name"
                    type="text"
                    value={this.training.name}
                    onChange={(evt) =>
                      this.handleChange("name", evt.target.value)
                    }
                    className="form-control"
                  />
                </this.FormField>
                <this.FormField name="technique">
                  <label htmlFor="technique">Technique</label>
                  <select
                    id="technique"
                    value={this.training.techniqueId}
                    onChange={(evt) =>
                      this.handleChange("techniqueId", evt.target.value)
                    }
                    className="form-control"
                  >
                    <option value="">Choose a Technique</option>
                    {this.store.techniques.map((tec) => (
                      <option value={tec.id} key={tec.id}>
                        {tec.name} {tec.family ? ` (${tec.family.name})` : null}
                      </option>
                    ))}
                  </select>
                </this.FormField>

                <this.FormField name="hook">
                  <label htmlFor="hook">Description</label>
                  <textarea
                    id="hook"
                    className="form-control"
                    value={this.training.hook}
                    onChange={(evt) =>
                      this.handleChange("hook", evt.target.value)
                    }
                  />
                </this.FormField>

                <this.FormField name="order">
                  <label htmlFor="order">Order</label>
                  <input
                    id="order"
                    className="form-control"
                    type="number"
                    min="1"
                    step="1"
                    value={this.training.order}
                    onChange={(evt) =>
                      this.handleChange("order", evt.target.value)
                    }
                  />
                </this.FormField>

                <this.FormField name="thumbnail">
                  <label htmlFor="thumbnail">Thumbnail</label>
                  {this.training.thumbnailUrl && (
                    <Thumbnail src={this.training.thumbnailUrl} />
                  )}
                  <input
                    id="thumbnail"
                    type="file"
                    ref={this.thumbnailRef}
                    key={this.store.training.thumbnailUrl}
                    className="form-control-file"
                  />
                </this.FormField>

                <this.FormField name="remoteModule">
                  <label htmlFor="remoteModule">Related Remote Module</label>
                  <select
                    id="remoteModule"
                    value={this.training.remoteModuleId || ""}
                    onChange={(evt) =>
                      this.handleChange("remoteModuleId", evt.target.value)
                    }
                    className="form-control"
                  >
                    <option value="">None</option>
                    {this.store.remoteModules.map((remoteMod) => (
                      <option value={remoteMod.id} key={remoteMod.id}>
                        {remoteMod.name}
                      </option>
                    ))}
                  </select>
                </this.FormField>

                <this.FormField name="active">
                  <label htmlFor="active">
                    <input
                      id="active"
                      type="checkbox"
                      checked={this.training.active}
                      onChange={(evt) =>
                        this.handleChange("active", evt.target.checked)
                      }
                      className="boolean"
                    />
                    Published <small>(Available to users)</small>
                  </label>
                </this.FormField>

                <this.FormField name="public">
                  <label htmlFor="public">
                    <input
                      id="public"
                      type="checkbox"
                      checked={this.training.public}
                      onChange={(evt) =>
                        this.handleChange("public", evt.target.checked)
                      }
                      className="boolean"
                    />
                    Public <small>(Free access)</small>
                  </label>
                </this.FormField>
              </div>

              <hr className="mb-4" />

              <this.FormField name="sections">
                <label htmlFor="sections">Sections</label>
                {this.training.sections && (
                  <TrainingSections
                    sections={this.training.sections}
                    onDelete={this.handleDeleteSection}
                  />
                )}
              </this.FormField>
            </div>
            <div className="text-right flex-grow">
              <div>
                <button
                  type="submit"
                  className="button-primary mr-4"
                  onClick={this.handleSave}
                >
                  Save
                </button>
                <button
                  className="button-grey"
                  onClick={this.handlePreview}
                  data-toggle="modal"
                  data-target="#trainingPreviewModal"
                >
                  Preview
                </button>
              </div>
              {this.statusText ? (
                <div className="mt-4" style={{ color: this.statusColor }}>
                  {this.statusText}
                </div>
              ) : null}
            </div>
          </div>

          <TrainingComponents
            training={this.training}
            trainingComponents={this.store.training.trainingComponents}
            store={this.store}
          />
          <div className="container">
            <hr className="mt-8 mb-8" />
            <div className="flex w-full">
              <div className="w-1/2">
                <div>
                  <button
                    type="submit"
                    className="button-primary mr-4"
                    onClick={this.handleSave}
                  >
                    Save
                  </button>
                  <button
                    className="button-grey"
                    onClick={this.handlePreview}
                    data-toggle="modal"
                    data-target="#trainingPreviewModal"
                  >
                    Preview
                  </button>
                </div>
                {this.statusText ? (
                  <div className="mt-4" style={{ color: this.statusColor }}>
                    {this.statusText}
                  </div>
                ) : null}
              </div>
              <div className="w-1/2 text-right">
                <button
                  className="button-danger relative right-0"
                  onClick={this.handleDelete}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </form>
        {this.previewModal()}
      </Fragment>
    );
  }
}

EditTraining.propTypes = {
  config: PropTypes.object,
};

export default EditTraining;
