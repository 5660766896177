import { observable, toJS } from "mobx";

class Practices {
  @observable practices = [];

  init() {
    this.practices = [];
  }

  add(id, attrs = {}) {
    this.practices.push({ id: id, ...attrs });
  }

  all() {
    return this.practices.map((p) => toJS(p));
  }

  last() {
    return toJS(this.practices[this.practices.length - 1]);
  }

  allDone() {
    const videos = this.videos();
    if (videos.length > 0) {
      return videos.every((p) => p.active);
    } else {
      return this.practices.every((p) => p.active);
    }
  }

  getById(id) {
    if (id === null) return null;
    return this.practices.find((p) => p.id === id);
  }

  update(id, attrs) {
    const index = this.practices.findIndex((r) => r.id === id);
    this.practices[index] = {
      ...this.practices[index],
      ...attrs,
    };
    return this.practices[index];
  }

  map(fn) {
    return this.practices.map(fn);
  }

  videos() {
    return this.practices.filter((p) => p.practiceType === "video");
  }
}

let practices = new Practices();

export default practices;
