import React, { useState } from "react";
import BulkUploadInput from "../BulkUploadInput";
import gaTrackEvent from "../../gaTrackEvent";

const GA_EVENT_LABEL = "bulkupload";

const BulkUpload = () => {
  const [name, setName] = useState("file");

  const handleChange = () =>
    gaTrackEvent("manager", "choosefile", GA_EVENT_LABEL);

  const handleSkip = () => {
    setName(undefined);
    gaTrackEvent("manager", "skip", GA_EVENT_LABEL);
  };

  const handleSubmit = () =>
    gaTrackEvent("manager", "uploadusers", GA_EVENT_LABEL);

  return (
    <div className="form-inputs white-bg-inputs">
      {/*
          A hidden input is needed to ensure that the POST body isn't empty
          when this step is skipped
      */}
      <input name="nofile" type="hidden" />
      <BulkUploadInput
        name={name}
        onChange={handleChange}
        onSubmit={handleSubmit}
      />
      <div className="mt-12">
        <button
          className="button-form"
          onClick={handleSkip}
          type="submit"
        >
          Complete Setup
        </button>
        <span className="ml-6">
          (You can add teams and more users later)
        </span>
      </div>
    </div>
  );
};

export default BulkUpload;
