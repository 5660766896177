import React, { useState } from "react";
import Modal from "./Modal";
import RenewSubscription from "./RenewSubscription";

const RenewSubscriptionModal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || false);

  document.addEventListener("click", (e) => {
    const target = e.target;
    if (target.classList.contains("renew-control-open")) {
      setIsOpen(true);
    }
  });

  const closeModal = () => setIsOpen(false);

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <RenewSubscription
        account={props.account}
        onCancel={closeModal}
        subscriptionPlans={props.subscriptionPlans}
        renewal={props.renewal}
      />
    </Modal>
  );
};

export default RenewSubscriptionModal;
