import React, { useEffect, useState } from "react";
import Share from "./TrainingComponent/types/Share";

const Reshare = ({ user, training, trainingResult }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    document.querySelector(".reshare").addEventListener("click", (e) => {
      e.preventDefault();
      setIsOpen(true);
    });
  }, []);

  const closeShare = () => setIsOpen(false);

  return (
    <Share
      isOpen={isOpen}
      onRequestClose={closeShare}
      user={user}
      training={training}
      trainingResult={trainingResult}
    />
  );
};

export default Reshare;
